.popup-container
{
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background: rgba($brand-gray-06, 0.8);

	z-index: 11;
	@include transition;
	.popup	
	{
		@include transition;
		width: 1240px;
		@include absolute-center(x);
		background: $white-color;
		@include border-radius(3px);
		@include box-shadow(0px 15px 30px rgba($black-color, 0.1));

		@include opacity(0);

		&.open
		{
			@include opacity(1);
		}

		.popup-inner
		{
			padding: 140px 140px 120px 140px;
			position: relative;
		}

		.popup-head
		{
			text-align: center;
			margin-bottom: 30px;

			.popup-title
			{
				@include font-p(rem-calc(30px), $weight-light, rem-calc(34px), $brand-gray-01);
				strong
				{
					font-weight: $weight-medium;
				}

				a
				{
					font-size: rem-calc(30px);
				}
			}

			.popup-subtitle
			{	
				@include font-p(rem-calc(18px), $weight-regular, rem-calc(24px), rgba($brand-gray-02, 0.6));
				margin-bottom: 0;
			}
		}

		.popup-content
		{
			max-height: 450px;
			overflow: auto;
			p
			{
				@include font-p(rem-calc(17px), $weight-regular, rem-calc(26px), $brand-gray-02);
				letter-spacing: 0;
			}
		}

		.popup-action
		{
			@include absolute-center(x);
			top: auto;
			bottom: 60px;

			.closebtn
			{
				font-size: 30px;
				color: $brand-success-02;

				&:hover, &:active, &:focus
				{
					color: darken($brand-success-02, 10%);
				}
			}

		}

		@media (max-width: 1280px) 
		{
			width: 991px;
			.popup-inner
			{
				padding: 140px 100px 100px 100px;
			}
			.popup-action
			{
				bottom: 40px;
			}
		}

		@media (max-width: 1080px) 
		{
			@include max-width(80%);
			.popup-inner
			{
				padding: 140px 100px 100px 100px;
			}
			.popup-action
			{
				bottom: 40px;
			}
		}

		@media (max-width: 991px) 
		{
			@include max-width(80%);
			.popup-inner
			{
				padding: 80px 20px 100px 20px;
			}

			.popup-head
			{
				text-align: center;
				margin-bottom: 20px;

				.popup-title
				{
					font-size: rem-calc(20px);
					line-height: 1.3;
					a
					{
						font-size: rem-calc(20px);
					}
				}

				.popup-subtitle
				{	
					font-size: rem-calc(16px);
					line-height: 1.3;
				}
			}
				
			.popup-content
			{
				max-height: 100%;
				p
				{
					@include font-p(rem-calc(14px), $weight-regular, rem-calc(22px), $brand-gray-02);
					letter-spacing: 0;
				}
			}


			.popup-action
			{
				bottom: 40px;
			}
		}
	}

	.team-popup
	{
		.team-photo-container
		{
			position: absolute;
			top: 0;
			left: 50%;
			@include transform(translate(-50%, -30%));
			width: 180px;
			height: 180px;
		}

		@media (max-width: 991px) 
		{
			.team-photo-container
			{
				width: 100px;
			}
		}
	}

	.reports-popup {
		max-width: 640px;	

		.popup-inner {
			padding: 50px 0;

			.form-container {
				padding: 0 50px;
			}

			.btn-gray-md {
				display: initial;
				padding: 20px;
			}
		}
	}
}

.modal-container {
	.popup	
	{
		@include transition;
		width: 1240px;
		@include absolute-center(x);
		background: $white-color;
		@include border-radius(3px);
		@include box-shadow(0px 15px 30px rgba($black-color, 0.1));

		// @include opacity(0);

		&.open
		{
			@include opacity(1);
		}

		.popup-inner
		{
			padding: 140px 140px 120px 140px;
			position: relative;
		}

		.popup-head
		{
			text-align: center;
			margin-bottom: 30px;

			.popup-title
			{
				@include font-p(rem-calc(30px), $weight-light, rem-calc(34px), $brand-gray-01);
				strong
				{
					font-weight: $weight-medium;
				}

				a
				{
					font-size: rem-calc(30px);
				}
			}

			.popup-subtitle
			{	
				@include font-p(rem-calc(18px), $weight-regular, rem-calc(24px), rgba($brand-gray-02, 0.6));
				margin-bottom: 0;
			}
		}

		.popup-content
		{
			max-height: 450px;
			overflow: auto;
			p
			{
				@include font-p(rem-calc(17px), $weight-regular, rem-calc(26px), $brand-gray-02);
				letter-spacing: 0;
			}
		}

		.popup-action
		{
			@include absolute-center(x);
			top: auto;
			bottom: 60px;

			.closebtn
			{
				font-size: 30px;
				color: $brand-success-02;

				&:hover, &:active, &:focus
				{
					color: darken($brand-success-02, 10%);
				}
			}

		}

		@media (max-width: 1280px) 
		{
			width: 991px;
			.popup-inner
			{
				padding: 140px 100px 100px 100px;
			}
			.popup-action
			{
				bottom: 40px;
			}
		}

		@media (max-width: 1080px) 
		{
			@include max-width(80%);
			.popup-inner
			{
				padding: 140px 100px 100px 100px;
			}
			.popup-action
			{
				bottom: 40px;
			}
		}

		@media (max-width: 991px) 
		{
			@include max-width(80%);
			.popup-inner
			{
				padding: 80px 20px 100px 20px;
			}

			.popup-head
			{
				text-align: center;
				margin-bottom: 20px;

				.popup-title
				{
					font-size: rem-calc(20px);
					line-height: 1.3;
					a
					{
						font-size: rem-calc(20px);
					}
				}

				.popup-subtitle
				{	
					font-size: rem-calc(16px);
					line-height: 1.3;
				}
			}
				
			.popup-content
			{
				max-height: 100%;
				p
				{
					@include font-p(rem-calc(14px), $weight-regular, rem-calc(22px), $brand-gray-02);
					letter-spacing: 0;
				}
			}


			.popup-action
			{
				bottom: 40px;
			}
		}
	}

	.reports-popup {
		// max-width: 640px;	

		.modal-content {
			padding: 50px;

			.popup-head {
				text-align: center;
			}

			.btn-gray-md {
				display: initial;
				padding: 15px;
			}
		}
	}
}