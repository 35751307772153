/*----------  Root  ----------*/

html, body
{
	height: auto;
	min-height: 100%;
}

/*----------  HTML  ----------*/

html
{
	font-size: 16px !important;
}

/*----------  Reset All  ----------*/

h1, h2, h3, h4, h5, h6, p, a, s, i, ul, ol, li, b, blockquote
{
	@include reset;
}

a, span
{
	display: inline-block;
}

:focus
{
	-webkit-outline: 0 !important;
	-moz-outline: 0 !important;
	-ms-outline: 0 !important;
	-o-outline: 0 !important;
	outline: 0 !important;
}

/*----------  Reset  ----------*/

.reset
{
	@include reset;
}

/*----------  Icons  ----------*/

.fa
{
	font-family: $font-fontawesome !important;
}

/*----------  Links  ----------*/

a, .link, button
{
	@include link;
}

/*----------  Form Elements  ----------*/

input[type="*"], textarea, input, .form-control
{
	@include box-shadow(none);

	&:hover, &:active, &:focus
	{
		@include box-shadow(none);
	}
}

textarea
{
	max-width: 100%;
}

/*----------  Buttons  ----------*/

.btn
{
	white-space: normal;
}