/*----------  Link  ----------*/

@mixin link()
{
	cursor: pointer;
	text-decoration: none;
	
	@include transition;

	&:hover, &:active, &:focus
	{
		text-decoration: none;
	}
}