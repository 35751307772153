.covid-page {
	header {
		.header-inner {
			background-color: $white-color;
		}
	}
}

.covid-main
{
	.hero-wrapper .hero-container {
		background-position: center top;
		height: 100vh;

		>.container {
			height: 100%;
			@include display-flex(column, flex-start, center);
		}

		.hero-content-container {
			max-width: 100%;
			width: 100%;
			@include reset-position;
		}

		.hero-action {
			span {
				margin-bottom: 14px;
			}
		}
		.hero-content {
			margin-top: 160px;
			h1 {
				margin-bottom: 40px;
			}
		}

		@media (max-width: 1440px) {
            height: auto;
            min-height: auto;

            padding-top: 200px;
			>.container {
				@include justify-content(center);
			}

			.hero-content {
				margin-top: 30px;
				margin-bottom: 60px;
				h1 {
					margin-bottom: 20px;
				}
			}

			.hero-action {
				span {
					padding: 10px 30px;
				}
			}
		}

        @media (max-width: 1330px) {
            padding-top: 100px;
        }

        @media (max-height: 1080px) {
			.hero-content {
				margin-top: 30px;
			}
		}

		@include media-breakpoint-down(xs) {
			.hero-content {
				margin-bottom: 10px;
				margin-top: 0px;
				h1 {
					font-size: 32px;
				}
			}
		}
	}

	.covid-wrapper {
		@include background-defaults;
		background-position: top right;
	}

	.covid19-content-section {
		@include position;

		.covid-section-inner {
			// margin: 160px 0px;
		}

		.content-wrapper {
			height: 444px;
			p {
				@include font-p(rem-calc(17px), $weight-regular, rem-calc(26px), $brand-gray-01);
				letter-spacing: -0.43px;
				strong {
					font-weight: $weight-semibold;
				}
			}

			.row, .content-col {
				height: 100%;
				@include display-flex(column, flex-start, center);
			}


			.content-image-col {
				position: static;

				.content-image {
					@include position(absolute, 5, $top: 0, $bottom: 0, $right: 0, $left: 58.86%);
					@include background-defaults;

					img {
						display: none;
					}
				}
			}
		}

		@include media-breakpoint-down(lg) {
			.content-wrapper
			{
				.content-image-col .content-image {
					left: 50%;
				}
			}
		}
		@include media-breakpoint-down(md) {
			.covid-section-inner {
				margin: 80px 0px;
			}
			.content-wrapper {
				height: auto;

				.content-image-col .content-image {
					@include reset-position;
					margin-bottom: 20px;
					img {
						display: block;
					}
				}
			}
		}
	}

	.covid-block-section {
		padding-bottom: 160px;

		.cs-col {
			@include display-flex(column, center, center);
		}

		.simple-block {
			max-width: 450px;
			margin-left: auto;
			p {
				@include font-p(rem-calc(24px), $weight-regular, rem-calc(34px), $brand-gray-01);
				letter-spacing: -0.6px;
			}
		}

		.gray-block {
			padding: 60px;
			background-color: $brand-gray-05;
			p {
				@include font-p(rem-calc(17px), $weight-regular, rem-calc(26px), $brand-gray-01);
				letter-spacing: -0.6px;

				strong {
					font-weight: $weight-semibold;
				}
			}
		}

		@include media-breakpoint-down(md) {
			.gray-block {
				padding: 30px;
			}
		}
		@include media-breakpoint-down(sm) {
			padding-bottom: 30px;
			.gray-block {
				margin-bottom: 30px;
			}
			.simple-block {
				max-width: 100%;
			}
		}
	}

	.form-section {
		background-color: $brand-gray-05;
		padding: 160px 0;

		.section-title {
			margin-bottom: 80px;
			h2 {
				@include font-p(rem-calc(36px), $weight-light, rem-calc(44px), $brand-gray-01);
				letter-spacing: -0.9px;
				text-align: center;
			}
		}

		.form-container {
			.btn-container {
				margin-top: 20px;

				.btn-gray {
					width: 100%;
				}
			}
		}

		@include media-breakpoint-down(xs) {
			padding: 40px 0;

			.section-title {
				margin-bottom: 30px;

				h2 {
					font-size: 28px;
					line-height: 1.2;
				}
			}
		}
	}

	.valuation-section {
		.content-wrapper .valuation-foot {
			margin-bottom: 0;

			.action-container {
				margin-bottom: 0;
			}
		}
	}
}
