@mixin preloader($background_color: $brand-light, $preloader_color: $brand-info, $logo_animation: true)
{
	.nprogress, #nprogress
	{
		.bar, .spinner
		{
			z-index: 9999 !important;
		}

		.bar
		{
			background: $preloader_color;

			.peg
			{
				@include box-shadow(0 0 10px $preloader_color, 0 0 5px $preloader_color);
			}
		}

		.spinner
		{
			.spinner-icon
			{
				border-top-color: $preloader_color;
				border-left-color: $preloader_color;
			}
		}
	}

	.preloader
	{
		width: 100%;
		height: 100%;
		text-align: center;
		background: $background_color;

		@include position(fixed, 1111, 0, 0);

		.preloader-inner
		{
			width: 100%;
			padding: 15px;

			@include absolute-center(y);
		}

		.preloader-logo
		{
			@include transition;
			@include max-width(280px);

			@include media-breakpoint-down(sm)
			{
				max-width: 25%;
			}
		}

		&.invisible
		{
			@if $logo_animation == true
			{
				.preloader-logo
				{
					@include opacity(0);
					@include transform(scale(1.05));
				}
			}
		}
	}
}