.contact
{
	.row
	{
		margin: 0;
	}

	.form-section
	{
		@include max-width(940px);
	}

	.info-section
	{
		@include max-width(1360px);	
	}

	.info-section
	{
		background: #0cd398; /* Old browsers */
		background: -moz-linear-gradient(left, #0cd398 0%, #00b982 100%, #2989d8 100%, #00b982 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, #0cd398 0%,#00b982 100%,#2989d8 100%,#00b982 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, #0cd398 0%,#00b982 100%,#2989d8 100%,#00b982 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0cd398', endColorstr='#00b982',GradientType=1 ); /* IE6-9 */
		
		padding: 35px 60px;
		@include transform(translateY(-50%));
		@include box-shadow(0px 8px 20px rgba($brand-success-02, 0.2));

		.info-container
		{
			address
			{
				@include font-p(rem-calc(17px), $weight-light, rem-calc(24px), $white-color);
				margin-bottom: 0px;
				strong
				{
					font-weight: $weight-semibold;
				}
			}

			a
			{
				@include font-p(rem-calc(24px), $weight-regular, rem-calc(30px), $white-color);
				letter-spacing: -1px;
				display: block;

				&.tel
				{
					text-align: center;
				}
			}
		}
	}

	.form-section
	{
		padding-bottom: 120px;
		.section-title
		{
			margin: 110px 0 50px;
			text-align: center;
		}

		.btn-container
		{
			margin-top: 35px;
		}

		.service-radio-container
		{
			.service-radio-block
			{
				background: transparent;
				width: 100%;
				padding: 50px 0;
				text-align: center;
				@include transition;
				@include position(relative, 6);

				&:hover, &:active, &:focus
				{
					cursor: pointer;
					background: $white-color;
					@include box-shadow(0px 20px 40px rgba($black-color, 0.03));
				}

				.service-image
				{
					@include max-width(70px);
					margin-bottom: 40px;
				}

				h2
				{
					@include font-p(rem-calc(30px), $weight-light, rem-calc(42px), $brand-gray-01);
				}

				&:after {
					content: '\f17b';
					font-family: $font-lineawesome;
					position: absolute;
					bottom: 0;
					left: 50%;
					@include transform(translate(-50%, 50%));
					@include border-radius(999px);
					@include transition;
					background: $brand-success-02;
					padding: 0;
					color: $white-color;
					font-size: 0;
					width: 0;
					height: 0;
				}

				&.checked
				{
					background: $white-color;
					@include box-shadow(0px 20px 40px rgba($black-color, 0.03));
					&:after {
						width: 48px;
						height: 48px;
						padding: 12px 14px;
						font-size: rem-calc(20px);
					}
				}
			}
		}

		.custom-file-input::-webkit-file-upload-button {
			visibility: hidden;
		}
		
		.custom-file-input-label
		{
			width: 100%;
			@include position(relative, 5);

			&:before 
			{
				content: 'Choose File(s)';
				text-align: center;
				background: $white-color;
				@include border-radius(2px);
				border: 0px;
				@include font-p(rem-calc(17px), $weight-regular, rem-calc(0), $brand-gray-01);
				@include box-shadow(0px 20px 40px rgba($black-color, 0.03));
				@include transition;
				@include placeholder($brand-gray-02);
				position: absolute;
				left: 0;
				right: 0;
				padding: 33px 0;
				height: auto;
			}

			&:after
			{
				content: '\f194';
				font-family: $font-lineawesome;
				color: $brand-success-02;
				position: absolute;
				z-index: 9;
				font-size: 24px;
				top: 50%;
				left: 20%;
				transform: translateY(-50%);;
				height: 25px;
			}

			&:hover
			{
				cursor: pointer;
				&:before 
				{
					@include box-shadow(0px 20px 40px rgba($black-color, 0.03));
				}
			}

			&:active, &:focus
			{
				&:before 
				{
					@include box-shadow(0px 20px 40px rgba($black-color, 0.08));
				}
			}
		}

		.custom-file-input {
			min-height: 66px;
			display: block;
			width: 99%;
			height: 100%;
			@include opacity(1);
		}
	}

	@media (max-width: 1100px) 
	{
		.info-section
		{
			.info-container
			{
				a
				{
					font-size: rem-calc(18px);
				}
			}
		}
	}

	@media (max-width: 991px) 
	{
		.info-section
		{
			.info-container
			{
				a
				{
					&.tel
					{
						text-align: left;
					}
				}
			}
		}
	}

	@media (max-width: 767px) 
	{
		.info-section
		{
			.info-container
			{
				text-align: center;
				address, a, a.tel
				{
					margin-bottom: 10px;
					text-align: center;
				}
			}
		}

		.form-section
		{
			.section-title
			{
				margin: 50px 0;
				text-align: center;
			}

			.service-radio-container
			{
				.service-radio-block
				{
					padding: 30px 0;
					.service-image
					{
						max-width: 40px;
						margin-bottom: 0px;
					}

					h2
					{
						font-size: rem-calc(20px);
					}

					&.checked
					{
						&:after {
							width: 30px;
							height: 30px;
							padding: 3px;
							font-size: rem-calc(20px);
						}
					}
				}
			}

			.custom-file-input {
				min-height: 44px;
			}

			.custom-file-input-label {
				&:before 
				{
					padding: 22.5px 0;
				}

				&:after
				{
					left: 24px;
				}
			}
		}
	}

	@media (max-width: 575px) 
	{
		.form-section
		{
			.service-radio-container
			{
				.service-radio-block
				{
					margin-bottom: 20px;
				}
			}
		}
	}
}