.teams-section
{
	@include max-width(1920px);
	padding: 120px 50px 0px;

	@media (max-width: 1200px) 
	{
		padding: 120px 15px 0px;
	}
	@media (max-width: 991px) 
	{
		padding: 120px 5px 0px;
	}

	>.row
	{
		@include justify-content(center);
		>.cs-col
		{
			@include flexbox;
			@include justify-content(center);
			@include flex-grow(0);
		}
	}
}

.team-block
{
	text-align: center;
	cursor: pointer;
	@include position(relative, 3);
	display: inline-block;
	margin: 0 25px;

	.img-container
	{
		background-color: $brand-gray-05;
		@include border-radius(9999px);
		@include transition;
		display: inline-block;
		width: 180px;
		height: 180px;
	}

	&:hover, &:active, &:focus
	{
		.img-container
		{
			padding: 50px;
		}

		.bio
		{
			@include transform(translateY(-40px));	
			@include opacity(100);
		}
	}

	.bio
	{
		// position: absolute;
		// left: 0;
		// right: 0;
		@include transition();
		@include max-width(200px);
		@include opacity(0);
		.bio-title
		{
			@include font-p(rem-calc(30px), $weight-light, rem-calc(26px), $brand-gray-01);
			small
			{
				display: block;
				@include font-p(rem-calc(20px), $weight-medium, rem-calc(26px), $brand-gray-01);
			}
			margin-bottom: 15px;
		}
		.bio-desc
		{
			@include font-p(rem-calc(16px), $weight-regular, rem-calc(20px), $brand-gray-02);
			margin-bottom: 5px;
		}

		.bio-link
		{
			@include font-p(rem-calc(18px), $weight-regular, rem-calc(34px), $brand-gray-01);
			&:hover, &:active, &:focus
			{
				color: $brand-success-02;
			}

			.icon
			{
				vertical-align: text-bottom;
			}
		}
	}

	@media (max-width: 767px) 
	{
		.img-container
		{
			padding: 50px;
		}

		.bio
		{
			@include transform(translateY(-40px));	
			@include opacity(100);

			.bio-title
			{
				font-size: rem-calc(20px);
				line-height: 1.1;
				small
				{
					font-size: rem-calc(16px);
					line-height: 1.2;
				}
			}
			.bio-desc
			{
				font-size: rem-calc(14px);
				line-height: 1.2;
			}

			.bio-link
			{
				font-size: rem-calc(15px);
				line-height: 2;
			}
		}
	}
}
