.faq-page
{
	.main-inner
	{
		@include background-defaults;
		background-size: contain;
		background-position: 330px -300px;
	}

	@media (max-width: 1440px) 
	{
		.main-inner
		{
			background-position: 330px 0px;
		}
	}

	@media (max-width: 991px) 
	{
		.main-inner
		{
			background-position: 150px 0px;
		}
	}

	.faq-section {
		@include position;
	}

	.faq-block {
		padding: 80px 0;
		border-bottom: 1px solid rgba($black-color, 0.1);
		&:last-child {
			border-bottom: 0;
		}

		.pre-title {
			@include font-p(rem-calc(14px), $weight-regular, 1.2, $brand-success-02);
			margin-bottom: 12px;
			text-transform: uppercase;
			letter-spacing: 1.4px;
		}

		h3 {
			margin-bottom: 30px;
			@include font-p(rem-calc(36px), $weight-light, 1.3, $brand-gray-01);
		}

		.steps-title {
			margin-top: 60px;
			margin-bottom: 40px;
		}

		strong {
			font-weight: $weight-semibold;
			// color: $black-c;
		}

		.steps-list {
			margin-bottom: 0;
			li {
				margin-bottom: 40px;
				padding-left: 0;
				&:before {
					display: none;
				}
			}

			.step-item {
				@include display-inline-flex(flex-start, space-between);
			}

			.step {
				min-width: 150px;
				margin-top: 5px;

				span {
					border: 2px solid $brand-success-02;
					border-radius: 5px;
					@include font-p(rem-calc(14px), $weight-regular, 1.2, $brand-success-02);
					text-transform: uppercase;
					padding: 2px 5px;
				}
			}
		}

		@media (max-width: 767px) 
		{
			padding: 30px 0;

			.steps-title {
				margin: 40px 0;
			}
			.steps-list {
				.step {
					min-width: 75px;
					margin-right: 15px;
				}
			}
		}

		@include media-breakpoint-down(xs)
		{
			h3 {
				font-size: 20px;
				font-weight: $weight-regular;
			}

			.steps-list {
				.step-item {
					@include flex-direction(column);
				}
				.step {
					min-width: 75px;
					margin-bottom: 25px;
					margin-right: 0;
				}
			}
		}
	}

	@include media-breakpoint-down(xs)
	{
		.faq-foot-content {
			display: none;
		}
		.faq-section .content-wrapper {
			.faq-foot {
				@include position(fixed, 2, $bottom: 0, $left: 0, $right:0);
				/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+30,ffffff+30&0+0,1+30,1+30,1+30 */
				background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 30%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 30%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

			}
		}
	}
}