/*----------  Position  ----------*/

@mixin position($position: relative, $z-index: 2, $top: null, $left: null, $right: null, $bottom: null)
{
	position: $position;
	top: $top;
	left: $left;
	right: $right;
	bottom: $bottom;
	z-index: $z-index;
}

/*----------  Absolute Center  ----------*/

@mixin absolute-center($axis: xy, $z-index: 5)
{
	position: absolute;
	z-index: $z-index;

	@if $axis == xy
	{
		top: 50%;
		left: 50%;

		@include transform(translate(-50%, -50%));
	}
	@else if $axis == x
	{
		top: 0;
		left: 50%;

		@include transform(translateX(-50%));
	}
	@else if $axis == y
	{
		left: 0;
		top: 50%;

		@include transform(translateY(-50%));
	}

	@content;
}