/*----------  Body  ----------*/

body
{
	@include preloader($white-color, $indigo, true);
	@include font-p($font-base, $weight-regular, 1.2, $brand-dark);
	@include position(relative);

	&.loading
	{
		overflow: hidden;
	}
}

.container-xl
{
	@include extend-container(1240px, 30px);
}

.preloader-container
{
	height: 100vh;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: white;
    z-index: 999;

    img
    {
		@include absolute-center;
    }
}

a
{
	@include font-p(rem-calc(18px), $weight-regular, rem-calc(34px), $brand-gray-01);
	@include transition;
	// -webkit-transition-duration: 0.3s !important;
	//    -moz-transition-duration: 0.3s !important;
	// 	-ms-transition-duration: 0.3s !important;
	// 	 -o-transition-duration: 0.3s !important;
	// 		transition-duration: 0.3s !important;
	&:hover, &:active, &:focus
	{
		color: $brand-success-01;
	}

	.icon
	{
		color: $brand-success-01;
		vertical-align: text-bottom;
	}


	@media (max-width: 991px)
	{
		font-size: rem-calc(16px);
		line-height: rem-calc(26px);
	}
}

.text-green
{
	color: $brand-success-01 !important;
}

.btn
{
	@include font-p(rem-calc(16px), $weight-regular, rem-calc(26px), $brand-gray-01);
	@include transition;
	border: 2px solid $brand-success-02;
	@include border-radius(3px);
	background: transparent;

	&:hover, &:active, &:focus
	{
		color: $white-color;
		background: $brand-success-02;
		@include box-shadow(0px 15px 30px rgba($brand-success-02,0.25));
	}
}

.btn-1
{
	@include font-p(rem-calc(16px), $weight-regular, rem-calc(19px), $white-color);
	@include transition;
	border: 2px solid $brand-success-01;
	padding: 15px 30px;
	@include border-radius(999px);
	background: $brand-success-01;
	@include box-shadow(0px 30px 40px rgba($brand-success-01,0.25));

	&:hover, &:active, &:focus
	{
		color: $white-color;
		background: $brand-success-02;
		border-color: $brand-success-02;
		@include box-shadow(0px 15px 30px rgba($brand-success-02,0.25));
	}

	&.light
	{
		background-color: $white-color;
		color: $brand-success-01;
		border-color: $white-color;
		@include box-shadow(none);

		&:hover, &:active, &:focus
		{
			background-color: $brand-success-01;
			border-color: $brand-success-01;
			color: $white-color;
			@include box-shadow(0px 15px 30px rgba($white-color,0.25));
		}
	}
}

.btn-curved
{
	@include font-p(rem-calc(20px), $weight-regular, rem-calc(34px), $white-color);
	@include transition;
	padding: 19px 40px;
	@include border-radius(0px 999px 999px 999px);
	background: $brand-success-01;
	@include box-shadow(0px 16px 20px rgba($brand-success-01,0.15));
	letter-spacing: -0.5px;
	// cursor: pointer;

	// &:hover, &:active, &:focus
	// {
	// 	color: $white-color;
	// 	background: $brand-success-02;
	// 	@include box-shadow(0px 15px 30px rgba($brand-success-02,0.25));
	// }

	@include media-breakpoint-down(xs)
	{
		padding: 15px 20px;
		line-height: 1.2;
		font-size: rem-calc(16px);
	}
}

.btn-bordered
{
	@include font-p(rem-calc(16px), $weight-regular, rem-calc(26px), $brand-gray-01);
	@include transition;
	border: 2px solid $brand-success-01;
	@include border-radius(999px);
	padding: 15px 30px;
	background: transparent;

	&:hover, &:active, &:focus
	{
		color: $white-color;
		background: $brand-success-01;
		@include box-shadow(0px 15px 30px rgba($brand-success-01,0.25));
	}

	&.md {
		line-height: 19px;
	}
}

.btn-bordered-gray
{
	@include font-p(rem-calc(16px), $weight-regular, rem-calc(26px), $brand-gray-01);
	@include transition;
	border: 2px solid $brand-gray-01;
	@include border-radius(999px);
	padding: 15px 30px;
	background: transparent;

	&:hover, &:active, &:focus
	{
		color: $white-color;
		background: $brand-gray-01;
		@include box-shadow(0px 15px 30px rgba($brand-gray-01,0.25));
	}

	&.md {
		line-height: 19px;
	}
}

.btn-gray
{
	@include font-p(rem-calc(13px), $weight-medium, rem-calc(40px), $white-color);
	@include transition;
	border: 0px;
	@include border-radius(2px);
	background: $brand-gray-01;
	padding: 0 30px;
	.la
	{
		color: $brand-success-01;
		@include transition;
		font-size: rem-calc(14px);
	}

	&.btn-gray-md
	{
		font-size: rem-calc(17px);
		font-weight: $weight-regular;
		line-height: 1.2;
		padding: 20px 0;
		text-align: center;
		display: block;
	}

	&:hover, &:active, &:focus
	{
		color: $white-color;
		background: $brand-success-01;

		.la
		{
			color: $white-color;
		}

		&.btn-gray-md
		{
			@include box-shadow(0 30px 40px 0 rgba($brand-success-01, 0.25));
		}
	}

}

.btn-gray-lg
{
	@include font-p(rem-calc(17px), $weight-regular, rem-calc(40px), $white-color);
	@include transition;
	border: 0px;
	@include border-radius(2px);
	background: $brand-gray-02;
	padding: 13px 30px;
	max-width: 100%;
	width: 450px;
	margin: 0 auto;
	@include box-shadow(0px 30px 40px rgba($brand-gray-02, 0.25));

	&:hover, &:active, &:focus
	{
		color: $white-color;
		background: $brand-success-02;
		@include box-shadow(0px 30px 40px rgba($brand-success-02, 0.25));
	}

	@media (max-width: 575px)
	{
		width: auto;
	}
}

.underline-link
{
	@include position(relative, 5);
	@include font-p(rem-calc(16px), $weight-medium, rem-calc(28px), $brand-gray-07);
	text-transform: uppercase;

	strong
	{
		@include font-p(rem-calc(24px), $weight-medium, rem-calc(28px), $brand-gray-01);
		text-transform: none;
	}

	&:after {
		content: '';
		position: absolute;
		bottom: 0px;
		left: 50%;
		@include transform(translate(-50%, -100%));
		@include transition;
		width: 0%;
		height: 2px;
		background: $brand-success-02;
	}

	&:hover, &:active, &:focus, &.active
	{
		color: $brand-gray-01;

		&:after {
			width: 100%;
		}
	}

	@media (max-width: 991px)
	{
		strong
		{
			font-size: rem-calc(18px);
		}
	}
}

u
{
	text-decoration: none;
	@include position(relative, 5);
	line-height: inherit;

	&:after {
		content: '';
		position: absolute;
		bottom: 0px;
		left: 50%;
		@include transform(translate(-50%, -100%));
		@include transition;
		width: 100%;
		height: 2px;
		background: $brand-success-02;
	}
}


h1
{
	@include font-p(rem-calc(60px), $weight-light, rem-calc(64px), $brand-gray-01);
	margin-bottom: 15px;

	@media (max-width: 767px)
	{
		font-size: rem-calc(40px);
		line-height: rem-calc(42px);
	}
}

h2
{
	@include font-p(rem-calc(36px), $weight-light, rem-calc(40px), $brand-gray-01);
	@media (max-width: 767px)
	{
		font-size: rem-calc(24px);
		line-height: rem-calc(32px);
	}
}

h3
{
	@include font-p(rem-calc(30px), $weight-light, rem-calc(26px), $brand-gray-01);

	@media (max-width: 767px)
	{
		font-size: rem-calc(20px);
		line-height: rem-calc(22px);
	}
}

p
{
	@include font-p(rem-calc(17px), $weight-light, rem-calc(26px), $brand-gray-02);
	letter-spacing: -1px;
	margin-bottom: 20px;

	&:last-child
	{
		margin-bottom: 0;
	}

	@media (max-width: 767px)
	{
		font-size: rem-calc(15px);
		line-height: rem-calc(22px);
		letter-spacing: 0;
	}
}

time
{
	@include font-p(rem-calc(14px), $weight-regular, rem-calc(30px), $brand-gray-02);
	text-transform: uppercase;
}

.title {
	margin: 65px 0;
	@include font-p(rem-calc(14px), $weight-regular, rem-calc(26px), $brand-gray-03);
	text-transform: uppercase;

	@media (max-width: 1200px)
	{
		display: none;
	}
}

.vertical-title
{
	@include transform(rotate(-90deg));
}

.img-circle
{
	@include border-radius(9999px);
}

.btn-circle
{
	@include border-radius(9999px);
}

.slick-navbtn
{
	padding: 25px 31px;
	position: absolute;
	top: 50%;

	.icon
	{
		font-size: 14px;
		color: $brand-gray-01;
		@include transition;
		vertical-align: initial;

	}
	&:hover, &:active, &:focus
	{
		.icon
		{
			color: $white-color;
		}
	}

}

.slick-prev
{
	left: -158px;
	@include transform(translate(-50%, -50%));

	@media (max-width: 1700px)
	{
		left: -50px;
	}
}
.slick-next
{
	right: -158px;
	@include transform(translate(50%, -50%));
	@media (max-width: 1700px)
	{
		right: -50px;
	}
}

.block
{
	background: $white-color;
	padding: 50px;
	@include box-shadow(0px 20px 40px rgba($black-color, 0.05));
	margin-bottom: 25px;

	&:first-child
	{
		margin-top: 25px;
	}
}

.banner-title
{
	margin: 180px 0 80px;
	text-align: center;
	@include font-p(rem-calc(36px), $weight-light, rem-calc(45px), $brand-gray-01);
	strong
	{
		font-weight: $weight-medium;
	}

	&.banner-title-lg
	{
		font-size: rem-calc(48px);
	}

	@media (max-width: 991px)
	{
		margin: 120px 15px 40px;
		font-size: rem-calc(24px);

		&.banner-title-lg
		{
			font-size: rem-calc(32px);
		}
	}
}

.bg-gray
{
	background: $brand-gray-05;
}

.bg-teal {
	background: $brand-success-03;
}

.page-nav-container
{
	text-align: center;
	@include position(relative, 5);
	.page-nav
	{
		display: inline-block;
		margin-bottom: -6px;

		li
		{
			margin: 0 45px;
		}

		.underline-link
		{
			line-height: 45px;
		}
	}

	.nav-btn
	{
		position: absolute;
		bottom: 0;
		margin: 0 15px;
		@include transform(translateY(50%));
		text-transform: uppercase;

		&.right
		{
			right: 15px;
		}

		&.left
		{
			left: 0px;
		}

		.icon
		{
			color: $white-color;
			vertical-align: initial;
		}
	}

	@media (max-width: 991px)
	{
		margin: 0 15px;
		.page-nav
		{
			li
			{
				margin: 0 10px;
				a
				{
					font-size: rem-calc(14px);
				}
			}
		}
	}
}

.section-block-container
{
	margin-top: 80px;
	margin-bottom: 120px;

	@media (max-width: 991px)
	{
		margin-top: 40px;
		margin-bottom: 60px;
	}
}

.prefooter-section
{
	padding-bottom: 125px;

	.contact-link
	{
		@include font-p(rem-calc(36px), $weight-medium, rem-calc(45px), $brand-gray-01);
		text-transform: none;

		&:after {
			height: 3px;
			bottom: -3px;
			width: 100%;
		}

		&:hover, &:active, &:focus
		{
			color: $brand-success-01;
		}
	}

	@media (max-width: 767px)
	{
		padding: 30px 0;
		.contact-link
		{
			font-size: rem-calc(24px);
		}
	}
}

.form-group
{
	margin-bottom: 25px;

	label
	{
		@include font-p(rem-calc(17px), $weight-regular, rem-calc(26px), $brand-gray-01);

		small
		{
			font-size: rem-calc(17px);
			color: $brand-gray-02;
		}
	}
}

.form-control
{
	background: $white-color;
	@include border-radius(2px);
	border: 0px;
	padding: 20px 24px;
	@include font-p(rem-calc(17px), $weight-regular, rem-calc(26px), $brand-gray-01);
	@include box-shadow(0px 20px 40px rgba($black-color, 0.03));
	@include transition;
	@include placeholder($brand-gray-02);
	min-height: 66px;
	caret-color: $brand-success-02;

	&:hover
	{
		@include box-shadow(0px 20px 40px rgba($black-color, 0.03));
	}

	&:active, &:focus
	{
		@include box-shadow(0px 20px 40px rgba($black-color, 0.08));
		border-color: transparent;
		color: $brand-gray-01;
	}

	border: 1px solid transparent;
	&.parsley-error, &.error {
		border: 1px solid $brand-danger;
		@include box-shadow(0px 5px 5px rgba($brand-danger, 0.1));
	}

	@media (max-width: 767px)
	{
		min-height: 44px;
		padding: 10px 24px;
	}
}

select.form-control
{
	-moz-appearance:none; /* Firefox */
	-webkit-appearance:none; /* Safari and Chrome */
	appearance:none;
}

textarea.form-control
{
	min-height: 200px;
}
.select-wrapper
{
	@include position(relative, 5);
	&:after
	{
		content: "\f110";
		font-family: $font-lineawesome;
		position: absolute;
		right: 24px;
		color: $brand-success-02;
		top: 50%;
		@include transform(translateY(-50%));
	}
}

.bootstrap-select
{
	>.dropdown-toggle
	{
		@include reset();
		background-color: $white-color;
		padding: 20px 25px;
		@include font-p(rem-calc(17px), $weight-regular, 1.52, $brand-gray-01);
		letter-spacing: -0.43px;
		border-radius: 2px;

		&.bs-placeholder
		{
			color: $brand-gray-02;
		}

		&:after
		{
			border: 0px;
			margin-left: 25px;
			vertical-align: none;
			content: '\f110';
			font-family: $font-lineawesome;
			font-size: 12px;
			font-weight: $weight-bold;
			color: $brand-gray-02;
		}

		&:hover, &:active, &:focus
		{
			outline: none !important;
		}
	}

	>.dropdown-menu
	{
		.inner
		{
			margin-right: 8px;
			&::-webkit-scrollbar-track
			{
				border-radius: 30px;
				background-color: $brand-gray-05;
  				margin: 15px 0;
			}

			&::-webkit-scrollbar
			{
				width: 6px;
				background-color: $white-color;
			}

			&::-webkit-scrollbar-thumb
			{
				border-radius: 10px;
				background-color: $brand-gray-02;
			}
		}

		&.show
		{
			margin-top: 0;
			border: 0px;
			border-radius: 2px;
		}

		.dropdown-menu li a span.text {
			white-space: normal
		}

		ul.dropdown-menu
		{
			.disabled {
				display: none;
			}

			.dropdown-item
			{
				@include position;
				@include font-p(rem-calc(17px), $weight-regular, 1.52, $brand-gray-02);
				padding: 15px 20px 15px 45px;
				@include transition;

				&:before
				{
					content: '';
					height: 9px;
					width: 9px;
					border: 1px solid rgba($brand-gray-02, 0.6);
					background-color: $white-color;
					@include position(absolute, 2, 22px, 25px, auto, auto);
					@include border-radius(999px);
					@include transition;
				}

				&:hover, &:active, &:focus, &.active
				{
					background-color: $white-color;
					color: $brand-gray-01;
					&:before
					{
						background-color: $brand-success-01;
						border-color: $brand-success-01;
					}
				}
			}
		}
	}


}

.slider.slider-horizontal
{
	width: 100%;
	height: 24px;

	.slider-track
	{
		background: $white-color;
		height: 6px;
		@include border-radius(30px);
		@include box-shadow(none);
		margin-top: 0;
		@include transform(translateY(-50%));

		.slider-selection
		{
			background: $brand-success-01;
			@include border-radius(30px 0 0 30px);
			@include box-shadow(0 3px 6px 0 rgba($brand-success-01, 0.25));
		}
	}

	.slider-handle
	{
		margin-left: 0;
		height: 24px;
		width: 16px;
		background: $brand-success-01;
		@include box-shadow(0 10px 20px 0 rgba($brand-success-01, 0.25));

		&.round
		{
			@include border-radius(2px);
		}

		&:before, &:after
		{
			content: "..";
			display: block;
			line-height: 0.3;
			font-size: 25px;
			color: $white-color;
			letter-spacing: -1px;
			margin-left: 2px;
		}

		&:before
		{
			margin-top: -2px;
		}
	}
}

.parsley-errors-list
{
	@include extends('.list-unstyled');
	li {
		color: $brand-danger;
	}
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    display: inline-block;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    width: 18px;
    height: 18px;
    border: 1px solid $brand-success-01;
    border-radius: 100%;
    background: $white-color;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: $brand-success-01;
    position: absolute;
    top: 8px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.tracking-modal {
	.modal {

		&-content {
			border: 0px;
		}

		&-header {
			border-bottom: 0px;
		}

		&-body {
			p {
				color: $brand-gray-01;
			}
			.form-group {
				margin-bottom: 0;

				.form-control {
					border: 1px solid rgba($brand-gray-01, 0.1);
					padding: 10px;
				}
			}
		}

		&-footer {
			border-top: 0px;
		}
	}
}
