/*----------  Reset  ----------*/

@mixin reset()
{
	margin: 0;
	border: 0;
	padding: 0;
	line-height: 1.2;
	border-radius: 0;
	background: none;
}

@mixin reset-position()
{
	position: relative;
	top: auto;
	left: auto;
	bottom: auto;
	right: auto;

	@include transform(none);
}

@mixin reset-list()
{
	list-style-type: none;

	@include reset;
}