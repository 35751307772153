.hero-wrapper
{
	.service-page-hero
	{
		min-height: 640px;
		@include background-defaults;

		.hero-content-container
		{
			left: 0;
			transform: translateY(-50%);
			right: 0;
			max-width: 1240px;
			width: 100%;
			margin: 0 auto;

			.hero-title
			{
				font-size: rem-calc(48px);
				text-align: center;
				margin: 125px 0;
				img
				{
					margin-top: -8px;
					margin-right: 20px;
				}
			}

			.hero-content
			{
				text-align: center;
				@include max-width(950px);
			}

			.hero-subtitle
			{
				@include font-p(rem-calc(36px), $weight-light, rem-calc(44px), $brand-gray-01);
				margin-bottom: 10px;
			}

			p
			{
				@include font-p(rem-calc(17px), $weight-regular, rem-calc(26px), $brand-gray-02);
			}
		}

		@media (max-width: 767px) 
		{
			.hero-content-container
			{
				.hero-title
				{
					margin: 80px 0;
					font-size: rem-calc(36px);
					img
					{
						margin-top: -8px;
						margin-right: 0;
						max-width: 60px;
					}
				}

				.hero-subtitle
				{
					font-size: rem-calc(24px);
					line-height: 1.3;
				}

				p
				{
					font-size: rem-calc(15px);
					line-height: 1.4;
					letter-spacing: 0;
				}
			}
		}

		@media (max-width: 575px) 
		{
			.hero-content-container
			{
				.hero-title
				{
					img
					{
						display: none;
					}
				}
			}
		}
	}
}

.services-landing
{
	.hero-wrapper
	{
		.hero-container
		{
			min-height: 60vh;

			.hero-content-container
			{
				@include absolute-center;
			}
		}
	}

	.services-section
	{
		margin-bottom: 40px;
	}


	@media (max-width: 767px) 
	{
		.hero-wrapper
		{
			.hero-container
			{
				min-height: 300px;

				.hero-content-container
				{
					@include absolute-center(y);
				}
			}
		}
	}
}

.services-section
{
	text-align: center;
	@include max-width(1920px);
}

.services-block-container
{
	text-align: center;
	.service-button
	{
		@include position(relative, 3);
		min-height: 410px;
		border-bottom: 1px solid $brand-gray-04;
		@include transition;

		&.bordered
		{
			border-right: 1px solid $brand-gray-04;
			border-left: 1px solid $brand-gray-04;
			z-index: 1;
		}

		.service-block
		{
			width: 100%;
			@include absolute-center;
		}

		.service-image
		{
			margin-bottom: 20px;
			@include transition;
			backface-visibility: hidden;
		}

		&:before {
			content: '';
			height: 3px;
			background-color: $brand-success-02;
			width: 0;
			position: absolute;
			top: 0;
			left: 50%;
			@include transform(translateX(-50%))
			@include transition;
		}

		&:hover, &:active, &:focus
		{
			border-bottom: transparent;
			background: $white-color;
			@include box-shadow(0px 20px 40px rgba($black-color, 0.1));
			&:before {
				width: 100%;	
			}

			.service-image
			{
				// @include transform(scale(1.1, 1.1));
				margin-bottom: 0;
			}
		}

		@media (max-width: 1200px) 
		{
			min-height: 300px;
			.service-image
			{
				img
				{
					width: 60px;
				}
			}

			.service-content
			{
				h2
				{
					font-size: rem-calc(24px);
				}
			}
		}


		@media (max-width: 991px) 
		{
			.service-block
			{
				padding: 0px 20px;
			}
		}


		@media (max-width: 767px) 
		{
			min-height: 250px;
			
		}
	}
}