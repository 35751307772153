.financing
{
	.hero-wrapper
	{
		.financing-page-hero
		{
			min-height: 1080px;
			-webkit-background-size: 0;
			background-size: 0;

			-webkit-background-position: center center;
			-moz-background-position: center center;
			-ms-background-position: center center;
			-o-background-position: center center;
			background-position: center center;

			-webkit-background-repeat: no-repeat;
			-moz-background-repeat: no-repeat;
			-ms-background-repeat: no-repeat;
			-o-background-repeat: no-repeat;
			background-repeat: no-repeat;
			
			.financing-banner-img
			{
				width: 100%;
			}

			.hero-content-container
			{
				left: 0;
				transform: translateY(-50%);
				right: 0;
				max-width: 1240px;
				width: 100%;
				margin: 0 auto;
				padding: 0 15px;
				z-index: 5;

				.hero-title
				{
					@include font-p(rem-calc(72px), $weight-medium, rem-calc(74px), $white-color);
					letter-spacing: -2px;
					max-width: 75%;
				}

				.hero-link
				{
					@include font-p(rem-calc(24px), $weight-light, rem-calc(34px), $white-color);
					border: 2px solid rgba($brand-success-02, 0);
					@include border-radius(4px);
					padding: 10px 28px;
					&:hover, &:active, &:focus
					{
						border: 2px solid rgba($brand-success-02, 1);
					}
				}
			}
			
			.financing-banner-image
			{
				position: absolute;
				left: 0;
				right: 0;
				max-width: 1240px;
				width: 100%;
				padding: 0 15px;
				margin: 0 auto;
				text-align: right;
				top: 50%;
				transform: translateY(100%);
			}
		}

		@media (max-width: 1500px) 
		{
			.financing-page-hero
			{
				min-height: 800px;
				.hero-content-container
				{
					top: 40%;
					.hero-title
					{
						max-width: 69%;
					}
				}
			}
		}

		@media (max-width: 1200px) 
		{
			.financing-page-hero
			{
				min-height: 600px;
				-webkit-background-size: cover;
				background-size: cover;
				.financing-banner-img
				{
					display: none;
				}

				.hero-content-container
				{
					text-align: center;
					top: 50%;

					.hero-title
					{
						max-width: 100%;
					}
				}

				.financing-banner-image
				{
					text-align: center;
					transform: translateY(150%);
				}
			}
			
		}

		@media (max-width: 991px) 
		{
			.financing-page-hero
			{
				min-height: 400px;
				.hero-content-container
				{
					.hero-title
					{
						font-size: rem-calc(48px);
						line-height: rem-calc(50px);
					}

					.hero-link
					{
						font-size: rem-calc(18px);
						line-height: rem-calc(34px);
						padding: 5px 18px;
					}
				}

				.financing-banner-image
				{
					display: none;
				}
			}
			
		}

		@media (max-width: 575px) 
		{
			.financing-page-hero
			{
				.hero-content-container
				{
					.hero-title
					{
						font-size: rem-calc(28px);
						line-height: rem-calc(32px);
					}

					.hero-link
					{
						font-size: rem-calc(16px);
						line-height: rem-calc(20px);
						padding: 5px 10px;
					}
				}
			}
			
		}
	}

	.financing-section
	{
		@include max-width(1280px);
		padding: 80px;

		.section-content
		{
			text-align: center;
			padding: 80px 0;
			@include max-width(920px);
			p
			{
				margin-top: 20px;
			}
		}

		.financing-content
		{
			padding: 80px 0;
			
			p
			{
				&.lg
				{
					@include font-p(rem-calc(24px), $weight-light, rem-calc(32px), $brand-gray-01);
				}

				&.light
				{
					color: $brand-gray-02;
				}
			}
		}

		@media (max-width: 991px) 
		{
			.section-content, .financing-content
			{
				padding: 20px 0;
			}
		}
		@media (max-width: 767px) 
		{
			.financing-content
			{
				text-align: center;
				p
				{
					&.lg
					{
						font-size: rem-calc(15px);
						line-height: rem-calc(20px);
						margin-top: 20px;
					}
				}
			}
		}

		@media (max-width: 575px) 
		{
			padding: 40px 15px;
		}
	}

	.financing-process-container
	{
		.financing-process-block
		{
			cursor: default;
			background: $white-color;
			padding: 50px;
			margin-bottom: 30px;
			@include box-shadow(inset 0px 3px 5px 0px rgba(9,203,146,0), 0px 22px 30px rgba($black-color, 0.05));
			@include transition;

			

			h4,h5,p
			{
				@include transition;
				@include font-p(rem-calc(17px), $weight-regular, rem-calc(24px), $brand-gray-02);
				margin-bottom: 0;
			}

			h4
			{
				font-weight: $weight-medium;
				line-height: rem-calc(26px);
				color: $brand-gray-01;
				text-transform: uppercase;
				position: absolute;
				top: 100%;
				@include transform(translateY(-100%));
			}

			h5
			{
				font-weight: $weight-light;
				line-height: rem-calc(26px);
				@include transform(translateY(100%));
			}

			p
			{
				color: rgba($white-color, 0);
			}

			.process-block-inner
			{
				@include position(relative, 5);
				min-height: 205px;
				overflow: hidden;

				.financing-process-icon, .process-block-title, .process-block-content
				{
					@include transition;
				}

				.financing-process-icon
				{
					position: absolute;
					top: 0;	
					left: 0;	
				}

				.process-block-title
				{
					position: absolute;
					top: 100%;	
					left: 0;
					width: 100%;
					@include transform(translateY(-100%));
				}

				.process-block-content
				{
					position: absolute;
					top: 100%;	
					left: 0;
					@include transform(translateY(-100%));	
					display: none;
				}

				.block-count
				{
					@include font-p(rem-calc(255px), $weight-hairline, 0.8, rgba($white-color, 0.2));
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
				}
			}

			&:hover, &:active, &:focus
			{
				@include box-shadow(inset 0px 3px 5px 0px rgba(9,203,146,0.3), 0px 36px 46px rgba($brand-success-02, 0.24));
				background: $brand-success-03;

				h4,h5,p
				{
					color: $white-color;
				}

				h4
				{
					top: 0;
				}

				h5
				{
					@include transform(translateY(0%));
				}

				img
				{
					@include filter(brightness(0) invert(1));
				}
			}


			@media (max-width: 767px) 
			{
				.process-block-inner
				{
					@include position(relative, 5);
					min-height: 150px;

					.block-count
					{
						font-size: rem-calc(180px);
					}
				}
			}
		}
	}
}