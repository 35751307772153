/* Icon Pop */
@include keyframes(#{$nameSpace}-icon-pop) {
	50% {
		@include prefixed(transform, scale(1.5));
	}
}

@mixin icon-pop {
	@include hacks();
	@include prefixed(transition-duration, $mediumDuration);

	.hvr-icon {
		@include prefixed(transform, translateZ(0));
		@include prefixed(transition-duration, $mediumDuration);
		@include prefixed(transition-property, transform);
		@include prefixed(transition-timing-function, ease-out);
	}

	&:hover,
	&:focus,
	&:active {

		.hvr-icon {
			@include prefixed(animation-name, #{$nameSpace}-icon-pop);
			@include prefixed(animation-duration, $mediumDuration);
			@include prefixed(animation-timing-function, linear);
			@include prefixed(animation-iteration-count, 1);
		}
	}
}
