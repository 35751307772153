/* Icon Pulse Grow */
@include keyframes(#{$nameSpace}-icon-pulse-grow) {
	to {
		@include prefixed(transform, scale(1.3));
	}
}

@mixin icon-pulse-grow {
	@include hacks();

	.hvr-icon {
		@include prefixed(transform, translateZ(0));
		@include prefixed(transition-timing-function, ease-out);
	}

	&:hover,
	&:focus,
	&:active {

		.hvr-icon {
			@include prefixed(animation-name, #{$nameSpace}-icon-pulse-grow);
			@include prefixed(animation-duration, $mediumDuration);
			@include prefixed(animation-timing-function, linear);
			@include prefixed(animation-iteration-count, infinite);
			@include prefixed(animation-direction, alternate);
		}
	}
}
