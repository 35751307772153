/*----------  Max Widths  ----------*/

@mixin max-width($width, $center: true)
{
	max-width: $width;
	width: 100%;

	@if $center == true
	{
		margin: auto;
	}
}