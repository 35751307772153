.reports {
  &-page {
    .reports-section {
      .reports {
        &-content {
          margin-bottom: 40px;
        }
      
        &-blocks {
          margin-top: 40px;
          margin-bottom: 60px;
          @include display-flex(row, flex-start, flex-start);
          flex-wrap: wrap;
        }
      
        &-block {
          width: 274px;
          margin: 10px 10px;
          padding: 20px 40px;
          text-align: center;

          &.block-bg-success {
            background: rgba($brand-success-01, 0.1);
          }

          &.block-bg-grey {
            background: rgba($brand-gray-04, 0.5);
          }
        }
      }

      @media (max-width: 1270px) 
		  {
        .reports {
          &-blocks {
            @include align-items(center);
            @include justify-content(center);
          }

          &-block {
            width: 100%;
          }
        }
      }

      @media (max-width: 768px) 
		  {
        .reports {
          &-blocks {
            border-right: 0px !important;
            border-bottom: 1px solid #dee2e6;
            padding-bottom: 40px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
  