/*----------  Index View  ----------*/

.hero-wrapper
{
	.hero-container
	{
		min-height: 100vh;
		position: relative;
		-webkit-background-size: cover;
		background-size: cover;

		-webkit-background-position: center bottom;
		-moz-background-position: center bottom;
		-ms-background-position: center bottom;
		-o-background-position: center bottom;
		background-position: center bottom;

		-webkit-background-repeat: no-repeat;
		-moz-background-repeat: no-repeat;
		-ms-background-repeat: no-repeat;
		-o-background-repeat: no-repeat;
		background-repeat: no-repeat;

		.hero-content-container
		{
			position: absolute;
			max-width: 650px;
			left: 50%;
			top: 50%;
			@include transform(translate(-100%, -50%));

		}
	}
    @media (max-width: 1400px)
	{
        .hero-container
		{
			.hero-content-container
			{
                max-width: 550px;
            }
        }
    }
	@media (max-width: 1200px)
	{
		.hero-container
		{
			min-height: 600px;
			.hero-content-container
			{
				@include transform(translate(0%, -50%));
				text-align: center;
				max-width: 100%;
				padding: 0 30px;
				left: 0;
				right: 0;
			}
		}
	}

	@media (max-width: 767px)
	{
		.hero-container
		{
			min-height: 700px;
			.hero-content-container
			{
				@include transform(translate(0%, -50%));
				text-align: center;
				max-width: 100%;
				padding: 0 30px;
				left: 0;
				right: 0;
			}
		}
	}

	@media (max-width: 575px)
	{
		.hero-container
		{
			min-height: 700px;
			.hero-content-container
			{
				@include transform(translate(0%, -50%));
			}
		}
	}
}
