.valuation-calculator-page
{
	background: $brand-gray-05;
	.valuation-calculator-container
	{
		.wizard-head-wrapper
		{
			background-color: $white-color;
			padding: 200px 0 40px;

			.wizard-steps-container
			{
				@include display-inline-flex(center, space-between);

				.step-indicator
				{
					@include flex(1);
					text-align: center;
					@include position;

					&:before, &:after
					{
						content: '';
						background-color: rgba($brand-gray-02, 0.3);
						width: 100%;
						height: 1px;
						@include position(absolute, -1, 40px, 50%, 0 , auto);
					}

					&:after
					{
						background-color: $brand-success-01;
						height: 3px;
						width: 0%;
						top: 39px;
						@include transition;
					}


					&:last-child
					{
						&:before, &:after
						{
							display: none;
						}
					}

					.number
					{
						// z-index: 1;
						@include font-p(rem-calc(24px), $weight-regular, 1.2, $brand-gray-01);
						@include border-radius(999px);
						border: 1px solid rgba($brand-gray-02, 0.3);
						@include flexbox();
						@include justify-content(center);
						@include align-items(center);
						background-color: $white-color;
						margin: 0 auto 20px;
						width: 80px;
						height: 80px;
						@include transition;
					}
					p
					{
						@include font-p(rem-calc(14px), $weight-regular, 1.2, rgba($brand-gray-02, 0.6));
						text-transform: uppercase;
					}

					&.completed, &.active
					{

						.number
						{
							color: $white-color;
							border-color: $brand-success-01;
							background-color: $brand-success-01;
						}
					}

					&.completed
					{
						&:after
						{
							width: 100%;
						}
					}

					&.active
					{
						p
						{
							color: $brand-gray-01;
						}
						.number
						{
							@include box-shadow(0 30px 40px 0 rgba($brand-success-01, 0.25));
						}
					}
				}
			}
		}

		.wizard-form-wrapper
		{
			.wizard-form-container
			{
				@include position;
				.btnSlickPrev
				{
					@include font-p(rem-calc(14px), $weight-regular, 1.2, $brand-gray-02);
					@include position(absolute, 2, 50%, -40px, auto, auto);
					@include transform(translateY(-50%));
					span
					{
						color: $brand-gray-01;
						background-color: $brand-gray-02;
						font-size: 16px;
						padding: 32px 18px 32px 50px;
						margin-right: 12px;
						@include border-radius(999px);
						@include transition;
					}

					&:hover, &:active, &:focus
					{
						color: $brand-success-01;
						span
						{
							color: $white-color;
							background-color: $brand-success-01;
						}
					}
				}
			}

			.wizard-step
			{
				&-inner
				{
					padding: 120px 120px 185px;

					h2
					{
						margin-bottom: 25px;
					}

					p
					{
						@include font-p(rem-calc(17px), $weight-regular, 1.52, $brand-gray-01);
						letter-spacing: -0.43px;

						&.small
						{
							font-size: rem-calc(14px);
							letter-spacing: -0.35px;
							color: $brand-gray-02;
						}
					}

					.btn-gray {
						@include max-width(900px);
					}

					section
					{
						margin-top: 80px;
					}

					.bootstrap-select
					{
						width: 100%;
					}

					.form-group
					{
						label {
							@include font-p(rem-calc(24px), $weight-regular, 1.52, $brand-gray-01);
							letter-spacing: -0.6px;
							span
							{
								color: rgba($brand-gray-02, 0.6);
							}
						}

						.form-control
						{
							@include box-shadow(none);
							min-height: 60px;
							letter-spacing: -0.43px;
							&.parsley-error, &.error {
								@include box-shadow(0px 5px 5px rgba($brand-danger, 0.1));
							}
						}

						.parsley-errors-list {
							display: none;
						}
					}

					.custom-checkbox {
						@include position;
						cursor: pointer;
						input {
							@include opacity(0);
							@include position(absolute);
						}

						label {
							@include font-p(rem-calc(17px), $weight-regular, 1.57, $brand-gray-02);
							letter-spacing: -0.43px;
							@include position;
							padding-left: 45px;
							@include transition;
							cursor: pointer;
							
							&:before
							{
								content: "";
								background-color: $white-color;
								width: 25px;
								height: 25px;
								border: 1px solid $brand-gray-02;
								@include transition;
								@include position(absolute, 2, 3px, 0, auto, auto);
							}

							&:after
							{
								content: "\f17b";
								font-family: $font-lineawesome;
								font-size: 14px;
								color: $brand-gray-02;
								font-weight: $weight-bold;
								@include opacity(0);
								@include transition;
								@include position(absolute, 2, 6px, 6px, auto, auto);
							}
						}

						input:checked + label {
							color: $brand-gray-01;
							&:before {
								background-color: $brand-success-01;
								border: 1px solid $brand-success-01;
							}
							&:after
							{
								@include opacity(1);
								color: $white-color;
							}
						} 

						&:hover, &:active, &:focus
						{
							label {
								color: $brand-gray-01;
							}
						}
					}
				}

				&-1
				{
					section
					{
						max-width: 900px;
						margin-left: auto;
						margin-right: auto;
						margin-bottom: 40px;
					}
				}

				&-2
				{
					section
					{
						margin-bottom: 40px;
						margin-top: 40px;

						.form-group
						{
							margin-bottom: 60px;
						}

						label
						{
							display: block;
							margin-bottom: 25px;
						}

						.input-help
						{
							margin-top: 11px;
							color: $brand-gray-01;
							letter-spacing: -0.43px;
						}
					}
				}

				&-3
				{
					section
					{
						margin-bottom: 60px;

						.form-group
						{
							label
							{
								margin-bottom: 0;
							}
							.cs-col
							{
								@include display-inline-flex(center, flex-start);
							}

							.tooltip-trigger
							{
								font-size: 32px;
								color: rgba($brand-gray-02, 0.3);
								margin-left: 15px;

								&:before
								{
									border-style: solid;
									border-width: 0 0 12px 12px;
									border-color: transparent transparent $white-color transparent;
									margin-bottom: 0;
									@include transform(translateX(7px));
								}

								&:after
								{
									width: 450px;
									background-color: #fff;
									@include box-shadow(0 10px 20px 0 rgba($black-color, 0.05));
									@include font-p(rem-calc(13px), $weight-regular, 1.5, $brand-gray-02);
									letter-spacing: -0.33px;
									@include text-shadow(none);
									white-space: initial;
									margin-bottom: 0;
									@include transform(translateX(7px));
								}

								.question-circle
								{
									@include font-p(rem-calc(24px), $weight-regular, 1.5, rgba($brand-gray-02, 0.6));
									font-style: normal;
									padding: 2px 10px;
									border: 1px solid rgba($brand-gray-02, 0.3);
									@include border-radius(999px);
									@include transition;
								}


								&:hover, &:active, &:focus
								{
									&:before, &:after
									{
										@include transform(translateX(15px));
									}

									.question-circle
									{
										background: $brand-gray-01;
										border-color: transparent;
										color: $white-color;
									}
								}
							}
						}
					}
				}

				&-4
				{
					section
					{
						margin-bottom: 60px;
					}

					.contact-section-content
					{
						margin-top: 80px;
						ul
						{
							@include extends('.list-unstyled');
						}

						li
						{
							margin-bottom: 30px;
							

							&:last-child
							{
								margin-bottom: 0;
							}
						}

						p
						{
							@include font-p(rem-calc(14px), $weight-regular, 1.57, $brand-gray-02);
							letter-spacing: -0.35px;

							a
							{
								@include font-p(rem-calc(14px), $weight-regular, 1.57, $brand-gray-02);
								color: $brand-success-01;
							}
						}
					}
				}
			}
		}

		@media (max-width: 1500px) 
		{
			.wizard-form-wrapper
			{
				.wizard-form-container
				{
					.btnSlickPrev
					{
						top: 30px;
						@include transform(none);
					}
				}
			}
		}

		@media (max-width: 1199px)  {
			.wizard-form-wrapper {
				.wizard-step-inner {
					padding-left: 60px;
					padding-right: 60px;
				}
			}
		}

		@media (max-width: 991px) 
		{
			.wizard-form-wrapper
			{
				.wizard-step
				{
					&-inner
					{
						padding-bottom: 40px;
						padding-top: 120px;

						section
						{
							margin-top: 40px;
						}
					}
					&-1, &-2, &-3, &-4
					{
						section
						{
							margin-bottom: 40px;
						}
					}
				}
			}
		}

		@media (max-width: 767px) 
		{
			.wizard-head-wrapper
			{
				display: none;
			}
			.wizard-form-wrapper
			{
				.wizard-step
				{
					&-inner
					{
						padding: 0;

						.bootstrap-select
						{
							margin-bottom: 20px;
						}

						.form-group
						{
							label
							{
								font-size: rem-calc(18px);
							}

							p
							{
								font-size: rem-calc(14px);
							}
						}
					}

					&-1
					{
						.wizard-step-inner
						{
							padding-top: 120px;
						}
					}

					&-2
					{
						section .form-group
						{
							margin-right: 30px;
							margin-bottom: 30px;
							&:last-child
							{
								margin-bottom: 0;
							}
						}
					}

					&-3
					{
						.wizard-step-inner
						{
							.form-group
							{
								margin-bottom: 10px;
								.tooltip-trigger
								{
									margin-bottom: 10px;
									&:after
									{
										width: 350px;
									}

									.question-circle
									{
										padding: 0px 5px;
										font-size: rem-calc(17px);
									}
								}

								p
								{
									font-size: rem-calc(14px);
								}
							}
						}
					}

					&-4
					{
						.contact-section-content 
						{
							margin-top: 0px;
							ul
							{
								margin-bottom: 40px;
							}
						}
					}
				}
			}
		}

		@media (max-width: 575px) 
		{
			.wizard-form-wrapper
			{
				.wizard-step
				{
					&-3
					{
						.wizard-step-inner
						{
							.form-group
							{
								margin-bottom: 20px;
								label
								{
									margin-bottom: 15px;
								}

								.tooltip-trigger
								{
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}