.news-section
{
	@include max-width(1920px);
	.section-title
	{
		text-align: center;	
		padding: 20px 0 40px;
		width: 50%;
	}

	@media (max-width: 767px) 
	{
		.section-title
		{
			width: 100%;
		}
	}
}

.news-block
{
	margin-bottom: 80px;
	.news-img
	{
		margin-left: 0;
		margin-right: 150px;
		img
		{
			@include box-shadow(20px 20px 30px rgba($black-color, 0.08));
		}
	}

	.news-content
	{
		max-width: 450px;

		.news-title
		{
			margin: 20px 0;
		}

		.news-link
		{
			vertical-align: sub;
		}
	}

	&.inverse
	{
		.news-content
		{
			margin-left: auto; 
			text-align: left;
		}

		.news-img
		{
			margin-right: 0;
			margin-left: 150px;
			text-align: right;
			@include background-defaults;

			img
			{
				@include box-shadow(-20px 20px 30px rgba($black-color, 0.08));
			}
		}
	}	

	@media (max-width: 1200px) 
	{
		.news-img
		{
			margin-right: 50px;
		}

		&.inverse
		{
			.news-img
			{
				margin-left: 50px;
			}
		}
	}


	@media (max-width: 991px) 
	{
		.news-content
		{
			padding: 0px 20px;
		}
	}

	@media (max-width: 767px) 
	{

		&:last-child
		{
			margin-bottom: 0;
		}
		
		.news-img
		{
			margin-right: 0px;
		}

		.news-content
		{
			max-width: 100%;
			margin-right: 0;
			padding: 20px;
		}

		&.inverse
		{
			.news-img
			{
				margin-left: 0px;
			}

			.news-content
			{
				margin-left: 0;
			}
		}
	}
}

.news-section-footer
{
	.more-btn
	{
		@include font-p(rem-calc(18px), $weight-regular, rem-calc(30px), $brand-gray-01);
		&:hover, &:active, &:focus
		{
			color: $brand-success-01;
		}
	}
}

.news-archive-section
{
	background: $white-color;
	padding: 70px 0;

	.news-archive-inner
	{
		@include max-width(1280px);
		padding: 0 15px;
	}

	.archive-section-title
	{
		margin-bottom: 15px;
	}

	.news-archive-item
	{
		margin-bottom: 20px;
		padding-left: 20px;
		@include transition;
		@include position(relative, 5);
		
		.news-archive-link
		{
			@include transition;
			line-height: 1;
		}

		span
		{
			@include transition;
			@include font-p(rem-calc(15px), $weight-medium, rem-calc(20px), $brand-gray-01);
		}

		time
		{
			@include font-p(rem-calc(13px), $weight-regular, rem-calc(20px), $brand-gray-02);
		}

		&:before {
			content: '\f112';
			font-family: $font-lineawesome;
			position: absolute;
			top: 0;
			color: transparent;
			@include transition;
			@include transform(translateX(10px));
		}

		&:hover, &:active, &:focus
		{
			span
			{
				color: $brand-success-01;
			}

			.news-archive-link
			{
				@include transform(translateX(20px));
			}

			&:before {
				color: $brand-success-01;
				@include transform(translateX(0px));
			}
		}
	}

	@media (max-width: 767px) 
	{
		margin-bottom: 40px;
		.archive-section-title, .archive-section-link
		{
			margin-bottom: 20px;
		}

		.archive-section-title
		{
			float: left;
		}

		.archive-section-link
		{
			float: right;
		}

		.news-archive-item
		{
			margin-bottom: 20px;
			padding-left: 20px;
			@include transition;
			@include position(relative, 5);
			
			.news-archive-link
			{
				@include transition;
				line-height: 1;
			}

			span
			{
				@include transition;
				@include font-p(rem-calc(15px), $weight-medium, rem-calc(20px), $brand-gray-01);
			}

			time
			{
				@include font-p(rem-calc(13px), $weight-regular, rem-calc(20px), $brand-gray-02);
			}

			&:before {
				content: '\f112';
				font-family: $font-lineawesome;
				position: absolute;
				top: 0;
				color: transparent;
				@include transition;
				@include transform(translateX(10px));
			}

			&:hover, &:active, &:focus
			{
				span
				{
					color: $brand-success-01;
				}

				.news-archive-link
				{
					@include transform(translateX(20px));
				}

				&:before {
					color: $brand-success-01;
					@include transform(translateX(0px));
				}
			}
		}
	}
}

.news-listing
{
	.row
	{
		margin: 0;
	}

	.page-nav-container
	{
		text-align: left;
		@include max-width(1280px);
		padding: 0 15px;
		
		.page-nav
		{
			li
			{
				margin: 0;
				margin-right: 90px;

				&:last-child
				{
					margin-right: 0;
				}
			}
		}
	}

	.news-section
	{
		@include max-width(1240px);
		padding: 0;
	}

	.news-block-container
	{
		margin-top: 0;
		padding-top: 80px;
	}

	.news-block
	{
		background: $white-color;
		@include box-shadow(0px 20px 30px rgba($black-color, 0.05));
		.news-img
		{
			margin-left: 0;
			margin-right: 20px;

			img
			{
				@include box-shadow(none);
			}
		}

		&.inverse
		{
			.news-img
			{
				margin-right: 0;
				margin-left: 20px;
				text-align: right;
				img
				{
					@include box-shadow(none);
				}
			}
		}

		.news-content
		{
			padding: 0 50px;
			max-width: 100%;

			p
			{
				letter-spacing: 0;
			}
		}
	}

	@media (max-width: 991px) 
	{
		.news-block, .news-block.inverse
		{
			.news-img, .news-img
			{
				margin: 0;
			}

			.news-content
			{
				padding: 0 30px;;
				.news-title
				{
					margin: 10px 0;
					font-size: rem-calc(26px);
					line-height: 1.3;
				}

				p
				{
					margin-bottom: 10px;
				}
			}
		}
	}

	@media (max-width: 767px) 
	{
		.page-nav-container
		{
			.page-nav
			{
				li
				{
					margin-right: 20px;
				}
			}
		}

		.news-block, .news-block.inverse
		{
			.news-content
			{
				padding: 30px;
				.news-title
				{
					margin: 10px 0;
					font-size: rem-calc(26px);
					line-height: 1.3;
				}

				p
				{
					margin-bottom: 10px;
				}
			}
		}
	}

	@media (max-width: 575px) 
	{
		.page-nav-container
		{
			.nav-btn
			{
				display: none;
			}
		}
	}
}

.news-single
{
	.banner-title
	{
		margin-bottom: 30px;
	}
	
	.page-nav-container
	{
		@include max-width(1280px);
		padding: 0 15px;
		text-align: left;
		.link-back
		{
			margin-bottom: 50px;
		}


	}

	.news-section
	{
		@include max-width(1240px);
		padding: 0;

	}
	
	.news-block-container
	{
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 80px;
	}

	.news-block
	{
		background: $white-color;
		margin: 0;
		@include box-shadow(0px 20px 30px rgba($black-color, 0.05));

		.news-img
		{
			margin: 0;
			img
			{
				display: none;
				@include box-shadow(none);
			}
			@include background-defaults;
			min-height: 450px;
		}

		.news-content
		{
			padding: 100px 130px;
			max-width: 100%;
			
			.news-title
			{
				font-size: rem-calc(60px);
				margin-bottom: 20px;
			}

			.news-subtitle
			{
				@include font-p(rem-calc(24px), $weight-light, rem-calc(32px), $brand-gray-02);
				margin-bottom: 40px;
			}

			p
			{
				@include font-p(rem-calc(17px), $weight-regular, rem-calc(26px), $brand-gray-01);
				letter-spacing: 0;
			}

			blockquote
			{
				@include font-p(rem-calc(24px), $weight-light, rem-calc(32px), $brand-gray-02);
				margin: 50px 0;

				cite
				{
					display: block;
					@include font-p(rem-calc(17px), $weight-regular, rem-calc(26px), $brand-gray-02);
					font-style: initial;
					margin: 10px 0;

					strong
					{
						font-weight: $weight-medium;
						color: $brand-gray-01
					}
				}
			}

			.news-content-footer
			{
				margin-top: 50px;

				time
				{
					@include font-p(rem-calc(13px), $weight-medium, rem-calc(24px), $brand-gray-06);
					letter-spacing: 1px;
					text-transform: uppercase;
				}

				.news-footer-link
				{
					li
					{
						padding: 0 13px;
						position: relative;
						&:after {
							content: '|';
							position: absolute;
							right: 0;
							color: $brand-gray-04;
						}

						&:last-child
						{
							&:after {
								content: '';
							}
						}
					}
				}

				.underline-link
				{
					@include font-p(rem-calc(13px), $weight-medium, rem-calc(24px), $brand-gray-01);
					text-transform: uppercase;
				}
			}
		}


		@media (max-width: 991px) 
		{
			.news-img
			{
				img
				{
					display: block;
				}
				min-height: auto;
			}

			.news-content
			{
				padding: 50px;
				max-width: 100%;
				
				.news-title
				{
					font-size: rem-calc(40px);
					line-height: 1.2;
					margin-bottom: 10px;
				}

				.news-subtitle
				{
					font-size: rem-calc(20px);
					line-height: 1.3;
					margin-bottom: 20px;
				}

				p
				{
					font-size: rem-calc(15px);
					line-height: 1.2;
				}

				blockquote
				{
					font-size: rem-calc(20px);
					line-height: 1.2;
					margin: 30px 0;

					cite
					{
						font-size: rem-calc(15px);
						line-height: 1.2;
						margin: 10px 0;
					}
				}

				.news-content-footer
				{
					margin-top: 30px;
				}
			}

		}

		@media (max-width: 991px) 
		{
			.news-content
			{
				padding: 50px 15px;
				.news-title
				{
					margin-top: 0;
				}
			}
		}

		@media (max-width: 575px) 
		{
			.news-content
			{
				.news-content-footer
				{
					text-align: center;
					time
					{
						float: none;
						display: block;
						margin-bottom: 10px;
					}

					.news-footer-link
					{
						text-align: center;
						float: none;
						@include justify-content(center);
					}
				}
			}
		}
	}

	@media (max-width: 575px) 
	{
		.banner-title
		{
			margin: 100px 15px 80px;
		}

		.page-nav-container
		{
			.nav-btn
			{
				span
				{
					display: none;
				}
			}

			.link-back
			{
				display: none;
			}
		}
	}
}

.news-archive
{

	.banner-wrapper
	{
		@include max-width(1280px);
		padding: 0 15px;
		@include position(relative, 5);

		.page-nav-container
		{
			position: absolute;
			top: 50%;
			right: 0;
			@include transform(translate(-100%, -50%));
			
			.slick-navbtn
			{
				position: relative;
			}

			.slick-prev
			{
				left: -10px;
				@include transform(translate(-50%, 0%));
			}
			.slick-next
			{
				right: -10px;
				@include transform(translate(-50%, 0%));
			}
		}

	}

	.news-archive-section
	{
		background: $brand-gray-05;
	}

	.archive-block
	{
		background: $white-color;
		display: block;
		padding: 30px 60px;
		@include transition;
		margin-bottom: 25px;

		.archive-content
		{
			.news-title
			{
				@include transition;
				@include font-p(rem-calc(24px), $weight-light, 1, $brand-gray-01);
			}
		}

		&:hover, &:active, &:focus
		{
			@include box-shadow(0px 20px 40px rgba($black-color, 0.03));
			.archive-content
			{
				.news-title
				{
					color: $brand-success-01;
				}
			}

		}
	}

	@media (max-width: 1200px) 
	{
		.banner-wrapper
		{

			.banner-title
			{
				margin-bottom: 40px;
			}

			.page-nav-container
			{
				position: relative;
				@include transform(none);
				margin-bottom: 20px;

				.slick-navbtn
				{
					padding: 15px 21px;;
				}

				.slick-prev
				{
					left: 0;
					@include transform(translate(0%));
				}
				.slick-next
				{
					right: 0;
					@include transform(translate(0%));
				}
			}
		}
	}

	@media (max-width: 767px) 
	{
		.archive-block
		{
			padding: 15px 30px;
		}
	}
	@media (max-width: 575px) 
	{
		.archive-block
		{
			padding: 15px;
		}
	}
}