.member-section
{
	padding: 150px 0 70px;
	text-align: center;
	
	.section-title
	{
		h3
		{
			@include font-p(rem-calc(20px), $weight-light, rem-calc(65px), $brand-gray-01);
			margin-bottom: 80px;
		}
	}

	.member-block {
		@include display-inline-flex(center, center);
	}

	.member-block-link {
		margin-right: 40px;

		img {
			max-height: 116px;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	@media (max-width: 1200px) 
	{
		padding: 70px;
		.section-title
		{
			h3
			{
				margin-bottom: 15px;
			}
		}
	}

}

.references {
	&-page {
		.banner-title {
			padding: 200px 0 100px;
			margin: 0;
			@include font-p(rem-calc(60px), $weight-light, rem-calc(64px), $brand-gray-01);
			letter-spacing: -3px;
		}
	}

	&-section {	
		padding: 0 12px;

		@include media-breakpoint-down(xs)
		{
			padding: 0;
		}
	}

	&-blocks {
		&-container {
			@include display-inline-flex(center, center);
			@include flex-wrap(wrap);
		}
	}
}

.reference {
	&-block {
		@include display-flex(column, center, center);
		height: 100%;

		&-item {
			max-width: 350px;
			width: 100%;
			margin: 0px 12px 24px;
			background-color: $white-color;
			@include border-radius(20px);
			overflow: hidden;
			@include transition;
			cursor: pointer;

			&:hover, &:active, &:focus
			{
				@include box-shadow(0 40px 60px 0 rgba($brand-success-01, 0.2));

				.reference-block-foot {
					background-color: $brand-success-01;
					p {
						color: $white-color;
					}
				}

				.reference-block-content {
					.reference-type {
						border-color: $brand-success-01;
						background-color: $brand-success-01;
						color: $white-color;
					}
				}
			}
		}

		&-content {
			@include flex(1);
			@include display-flex(column, center, center);
			padding: 24px;

			.img-container {
				margin-left: auto;
				margin-right: auto;

				img {
					max-width: 290px;
					max-height: 140px;
					@include media-breakpoint-down(xs)
					{
						width: 100%;
						height: auto;
    					max-height: 100%;
					}
				}
			}

			div {
				margin-bottom: 48px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.reference-type {
				@include font-p(16px, $weight-semibold, 1, $brand-success-01);
				letter-spacing: 1.6px;
				background-color: rgba($brand-success-04, 0.5);
				border: 1px solid #b8f2e1;
				border-radius: 6px;
				padding: 10px 14px;
				text-transform: uppercase;
				@include transition;
			}

			@include media-breakpoint-down(xs)
			{
				padding: 40px 24px;
				.img-container {
					img {
						height: auto;
						max-height: 100%;
					}
				}

				.reference-type {
					text-align: center;
				}
			}
		}

		&-foot {
			padding: 21px 24px;
			width: 100%;
			background-color: #f8fefc;
			border-top: 1px solid #b8f2e1;
			@include transition;
			p {
				@include font-p(24px, $weight-regular, 34px, $brand-success-01);
				text-align: center;
				letter-spacing: -0.6px;
				@include transition;
			}
		}
	}
}

/*Old UI Version*/
// .references-section
// {
// 	padding: 80px 0;
// 	@include max-width(1920px);
// 	h2
// 	{
// 		@include font-p(rem-calc(48px), $weight-light, rem-calc(65px), $brand-gray-01);
// 		text-align: center;
// 		margin-bottom: 70px;
// 	}

// 	h3
// 	{
// 		@include font-p(rem-calc(36px), $weight-light, rem-calc(54px), $brand-gray-01);
// 		text-align: center;
// 		margin-bottom: 70px;
// 	}

// 	.references-container
// 	{
// 		@include max-width(1280px);
// 		margin: 0px auto;
// 	}

// 	@media (max-width: 1500px) 
// 	{
// 		.references-container
// 		{
// 			max-width: 991px;
// 		}
// 	}

// 	@media (max-width: 1200px) 
// 	{
// 		padding: 40px 0;

// 		h2, h3
// 		{
// 			margin-bottom: 50px;
// 		}

// 		.references-container
// 		{
// 			max-width: 767px;
// 		}
// 	}

// 	@media (max-width: 767px) 
// 	{
// 		margin: 0;
// 		padding: 0;
// 		h2, h3
// 		{
// 			font-size: rem-calc(32px);
// 			margin-bottom: 30px;
// 		}

// 		.references-container
// 		{
// 			max-width: 100%;
// 			padding-bottom: 20px;
// 			margin-bottom: 0;
// 		}
// 	}
// }

// .reference-block
// {
// 	min-height: 300px;
// 	margin: 0 12px 20px;
// 	display: block;
// 	text-align: center;
// 	@include transition;
// 	z-index: 1;
// 	cursor: default;
// 	background: $white-color;
// 	@include position(relative, 9);
	
// 	&:before {
// 		content: '';
// 		height: 3px;
// 		background-color: $brand-success-02;
// 		width: 0;
// 		position: absolute;
// 		top: 0;
// 		left: 50%;
// 		@include transform(translateX(-50%))
// 		@include transition;
// 	}

// 	.img-container
// 	{
// 		@include transition;
// 		border-bottom: 1px solid $brand-gray-04;	
// 		margin-bottom: 35px;
// 		height: 185px;
// 		@include position(relative, 5);

// 		img
// 		{
// 			max-height: 140px;
// 			padding: 0px 15px;
// 			display: inline-block;
// 			@include absolute-center;
// 		}

// 	}

// 	.reference-content
// 	{
// 		@include transition;
// 		padding: 0 15px 30px;
// 		.reference-title
// 		{
// 			@include font-p(rem-calc(24px), $weight-regular, rem-calc(34px), $brand-gray-01);
// 			letter-spacing: 0;
// 		}

// 		.reference-desc
// 		{
// 			@include font-p(rem-calc(14px), $weight-regular, rem-calc(34px), $brand-gray-02);
// 			letter-spacing: 0;
// 			margin-bottom: 0;
// 		}
// 	}

// 	&:hover
// 	{
// 		@include box-shadow(0px 5px 20px rgba($black-color, 0.1));
// 		z-index: 5;

// 		&:before {
// 			width: 100%;	
// 		}
// 		.img-container
// 		{
// 			border-bottom-color: transparent;
// 			// margin-bottom: 65px;
// 		}

// 		.reference-content
// 		{
// 			// padding-bottom: 65px;
// 		}
// 	}

// 	@media (max-width: 767px) 
// 	{
// 		@include box-shadow(0px 5px 20px rgba($black-color, 0.1));
// 	}

// 	&.reference-dual-block
// 	{
// 		min-height: 300px;
// 		margin: 0 12px 20px;
// 		padding-bottom: 1px;
// 		display: block;
// 		text-align: center;
// 		@include transition;
// 		z-index: 1;
// 		background: $white-color;
// 		@include position(relative, 9);
		
// 		&:before {
// 			content: '';
// 			height: 3px;
// 			background-color: $brand-success-02;
// 			width: 0;
// 			position: absolute;
// 			top: 0;
// 			left: 50%;
// 			@include transform(translateX(-50%))
// 			@include transition;
// 		}

// 		.img-container
// 		{
// 			@include transition;
// 			margin-bottom: 35px;
// 			border-bottom: 0px;

// 			img
// 			{
// 				display: inline-block;

// 				&.img-sm
// 				{
// 					height: 130px;
// 				}
// 			}
			
// 			&.arrow
// 			{
// 				border-bottom: 1px solid $brand-gray-04;	
// 				@include position(relative, 5);
// 				&:after, &:before {
// 					top: 100%;
// 					left: 50%;
// 					border: solid transparent;
// 					content: " ";
// 					height: 0;
// 					width: 0;
// 					position: absolute;
// 					pointer-events: none;
// 				}

// 				&:after {
// 					border-color: rgba(213, 213, 213, 0);
// 					border-top-color: $white-color;
// 					border-width: 16px;
// 					margin-left: -16px;
// 				}
// 				&:before {
// 					border-color: rgba(238, 238, 238, 0);
// 					border-top-color: #ddd;
// 					border-width: 17px;
// 					margin-left: -17px;
// 				}
// 			}
// 		}

// 		.reference-content
// 		{
// 			padding-bottom: 0px;
// 		}

// 		.reference-title
// 		{
// 			@include font-p(rem-calc(24px), $weight-regular, rem-calc(34px), $brand-gray-01);
// 			letter-spacing: 0;
// 		}

// 		.reference-desc
// 		{
// 			@include font-p(rem-calc(14px), $weight-regular, rem-calc(34px), $brand-gray-02);
// 			letter-spacing: 0;
// 			margin-bottom: 0;
// 			@include absolute-center(x);
// 			top: auto;
// 			bottom: 0;
// 		}

// 		.reference-type
// 		{
// 			@include font-p(rem-calc(11px), $weight-medium, rem-calc(32px), $white-color);
// 			@include transition;
// 			text-transform: uppercase;
// 			background: $brand-gray-09;
// 			@include border-radius(3px);
// 			padding: 0px 12px;
// 			letter-spacing: 1px;
// 		}

// 		&:hover
// 		{
// 			@include box-shadow(0px 5px 20px rgba($black-color, 0.1));
// 			z-index: 5;

// 			&:before {
// 				width: 100%;	
// 			}

// 			.img-container
// 			{
// 				// border-bottom-color: transparent;
// 				// margin-bottom: 0px;
// 			}

// 			.reference-type
// 			{
// 				background: $brand-success-02;
// 			}

// 			.reference-content
// 			{
// 				padding-bottom: 0px;
// 			}
// 		}
// 	}
// }

