.advisory
{
	.advisory-section
	{
		@include max-width(1240px);
		padding: 0 15px;

		.section-title
		{
			text-align: center;
			margin: 50px 0 60px;
		}

		.advisory-missions-block-container
		{
			.mission-block
			{
				.mission-icon
				{
					float: left;
				}

				.mission-content
				{
					padding: 0px 40px 0px 80px;
					margin-bottom: 45px;

					h4
					{
						@include font-p(rem-calc(17px), $weight-medium, rem-calc(26px), $brand-gray-01);
						text-transform: uppercase;
						margin-bottom: 5px;
					}

					p
					{
						@include font-p(rem-calc(17px), $weight-regular, rem-calc(26px), $brand-gray-02);
					}
				}
			}
		}

	}
	@media (max-width: 767px) 
	{
		.hero-wrapper .service-page-hero
		{
			min-height: auto;
			.hero-content-container
			{
				position: relative;
				@include transform(none);

				.hero-title
				{
					padding: 200px 0 80px;
					margin: 0;
				}
			}
		}

		.advisory-section
		{
			.section-title
			{
				text-align: left;
				margin: 50px 0 30px;
				h2
				{
					font-weight: $weight-regular;
				}
			}

			.advisory-missions-block-container
			{
				.mission-block
				{
					.mission-content
					{
						padding: 0px 0px 0px 80px;
						margin-bottom: 30px;

						h4
						{
							font-size: rem-calc(15px);
							margin-bottom: 0;
						}

						p
						{
							font-size: rem-calc(15px);
						}
					}
				}
			}
		}
	}
}