/*----------  Footer  ----------*/

.newsletter {
	&-wrapper {
		padding: 30px 0;
	}

	&-form {
		@include display-inline-flex(center, center);
		flex-wrap: wrap;
		.form-control {
			padding: 18px 24px;
			min-height: auto;
			font-size: 13px;
			line-height: 1.5;
			min-height: 40px;
			margin-right: 15px;
			width: 200px;
			margin: 0px 15px 15px 0;
		}

		.btn-gray {
			&:hover, &:active, &:focus {
				color: $brand-gray-01;
				background-color: #fff;
			}
		}

		@media (max-width: 575px) 
		{
			@include display-flex(column, center, center);
			.form-control {
				margin-right: 0;
				width: 100%;
			}
		}
	}

}

footer
{
	padding: 30px 24px;
	.copyrights, .developedby
	{
		@include font-p(rem-calc(12px), $weight-regular, rem-calc(26px), $brand-gray-02);
		text-transform: uppercase;
		letter-spacing: 1px;

		a
		{
			@include font-p(rem-calc(12px), $weight-regular, rem-calc(26px), $brand-success-01);
		}
	}

	.navbar-container
	{
		display: inline-block;
		margin: 0 auto;

		// @media (max-width: 1350px) 
		// {
		// 	display: none;
		// }
	}

	@media (max-width: 1400px) 
	{
		.col-sm-auto
		{
			width: 100%;
		}

		.copyrights, .developedby
		{
			text-align: center;
		}

		.navbar-container
		{
			margin: 15px 0;
		}
	}

	@media (max-width: 991px) 
	{
		.navbar-container
		{
			>ul {
				@include flex-wrap(wrap);
				li {
					margin: 10px 0;
					width: 100%;
					@include flex(1);
				}
			}
		}
	}

	@media (max-width: 575px) 
	{
		.navbar-container
		{
			>ul {
				@include flex-direction(column);
			}
		}
	}
}