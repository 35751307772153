/*----------  Rem Base  ----------*/

$rem-base: 16px !default;

/*----------  Strip Unit Function  ----------*/

@function strip-unit($num)
{
	@return $num / ($num * 0 + 1);
}

/*----------  PX to Rem Conversion  ----------*/

@function convert-to-rem($value, $base-value) 
{
	$value: strip-unit($value) / strip-unit($base-value) * 1rem;
	@if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
	@return $value;
}

@function rem-calc($values, $base-value: $rem-base) 
{
	$max: length($values);

	@if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }

	$remValues: ();

	@for $i from 1 through $max 
	{
		$remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
	}

	@return $remValues;
}

/*----------  PX to Em Conversion  ----------*/

@function em-calc($values, $base-value: $rem-base)
{
	$remValues: rem-calc($values, $base-value: $rem-base);

	$max: length($remValues);

	@if $max == 1 { @return strip-unit(nth($remValues, 1)) * 1em; }

	$emValues: ();

	@for $i from 1 through $max 
	{
		$emValues: append($emValues, strip-unit(nth($remValues, $i)) * 1em);
	}

	@return $emValues;
}

// Deprecated: OLD EM CALC
@function emCalc($values) 
{
	@return em-calc($values);
}