/*----------  Header  ----------*/

header
{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9;

	.header-inner
	{
		@include transition;
		@include display-inline-flex(center, space-between);
		padding: 36px 24px;

		.nav-wrapper
		{
			@include display-inline-flex(center, flex-start);
		}

		.logo-container
		{
			width: 250px;
			text-align: center;
		}

		.brand-container
		{
			svg, img
			{
				width: 225px;
				height: 45px;
				// padding-top: 12px;
			}

			#path14
			{
				fill: #60686f;
			}
		}

		.button-container
		{
			width: auto;
			text-align: right;
			@include display-inline-flex(center, center);
		}


		.btn-contact
		{
			min-width: auto;
			margin-right: 40px;
			padding-right: 20px;
			font-size: 16px;
			@include position;
			&:after
			{
				content: '\f112';
				@include transition;
				font-family: $font-lineawesome;
				font-size: 16px;
				@include absolute-center(y);
				left: auto;
				right: 0;
				color: $brand-success-01;
			}

			&:hover, &:active, &:focus
			{
				&:after
				{
					@include transform(translate(5px, -50%));
				}
			}

			&.btn-unstyled {
				&:after {
					display: none;
				}
			}
		}

		.nav-container
		{
			// margin: auto;
		}

		.navbar-toggler
		{
			display: none;
		}


		.contactLink, .valuationLink
		{
			display: none;
		}

		&.header-light
		{
			.brand-container
			{
				#path14
				{
					fill: $white-color;
				}
			}

			.navbar-container
			{
				ul li
				{
					.menu-link
					{
						color: $white-color;
					}
					&:hover, &:active, &:focus, &.active
					{
						.menu-link
						{
							color: $brand-success-01;
						}
					}
				}
			}

			.btn-contact
			{
				color: $white-color;
			}
		}

		&.fixed
		{
			padding: 10px 24px;	
			background-color: rgba($white-color, 0.9);
			@include box-shadow(0px 1px 10px rgba($brand-gray-01, 0.2));

			.brand-container
			{
				#path14
				{
					fill: #60686f;
				}
			}

			.navbar-container
			{
				ul li
				{
					.menu-link
					{
						color: $brand-gray-01;
					}
					&:hover, &:active, &:focus, &.active
					{
						.menu-link
						{
							color: $brand-success-01;
						}
					}
				}
			}

			.btn-contact
			{
				color: $brand-gray-01;
			}
		}

		@media (max-width: 1330px) 
		{
			@include flex-direction(column);
			.nav-wrapper {
				@include justify-content(space-between);
			}

			.btn-contact
			{
				display: none;
			}

			.btn-1
			{
				padding: 10px 20px;
			}

			.contactLink
			{
				display: block;
			}
		}

		@media (max-width: 1199px) 
		{
			.brand-container svg
			{
				margin-top: 0;
			}
			.nav-wrapper {
				// @include flex-direction(column);
			}
		}
		
		@media (min-width: 1331px) 
		{
			.navbar-container
			{
				display: inline-block;
			}
		}

		@media (max-width: 1330px) 
		{
			display: block;
			padding: 0px 24px;
			.header-content-container
			{
				display: inline-block;
				margin: 10px 0;
				&.button-container
				{
					position: absolute;
					right: 24px;
					top: 0;
					margin: 13px 0;
					@include display-inline-flex(center, space-between);
				}
			}

			.nav-container
			{
				margin: 0;
				float: right;
				width: 100%;
				position: absolute;
				top: 100%;
				left: 0;
				z-index: 99;
				background: rgba($brand-success-01, 0.8);
			}
			
			.navbar-toggler
			{
				display: inline-block;
				vertical-align: middle;
				padding: 5px 12px;
				background: transparent;
				color: $brand-success-02;
				font-size: rem-calc(32px);
			}
			
			.navbar-container
			{
				ul
				{
					display: block;
					li
					{
						padding: 0px 33px 20px;
						text-align: right;
						&:first-child
						{
							padding: 20px 33px;
						}

						.menu-link
						{
							color: $white-color;
						}

						&.valuationLink
						{
							padding: 0px 9px 20px;
						}

						&:hover, &:active, &:focus, &.active
						{
							.menu-link
							{
								color: $brand-gray-01;
							}
						}
					}
				}
			}

			&.fixed, &.header-light
			{

				.navbar-container ul li
				{
					.menu-link
					{
						color: $white-color;
					}

					&:hover, &:active, &:focus, &.active
					{
						.menu-link
						{
							color: $brand-gray-01;
						}
					}
				}
			}
			
			&.fixed
			{
				padding: 0px 24px;
			}
		}

		@media (max-width: 767px) 
		{
			.btn-valuation
			{
				display: none;
			}

			.valuationLink
			{
				display: block;
			}
		}

		@media (max-width: 575px) 
		{	
			.brand-container
			{
				svg, img
				{
					width: 180px;
				}
			}
			padding: 0px;
			&.fixed
			{
				padding: 0px;
			}

			.btn-contact
			{
				min-width: auto;
			}

			.navbar-toggler
			{
				margin-left: 0px;
			}

			.header-content-container
			{
				&.logo-container
				{
					text-align: left;
					width: auto;
					margin: 10px;
				}
				&.button-container
				{
					right: 0px;
					width: auto;
				}
			}

			// .button-container
			// {
			// 	.btn-contact
			// 	{
			// 		display: none;
			// 	}
			// }

			// .contactLink
			// {
			// 	display: block;
			// }
		}

		@media (max-width: 420px) 
		{	
		}
	}

	.langmenu-wrapper
	{
		@include position;
		margin-left: 10px;
		.langmenu-active, .langmenu-container
		{
			width: auto;
		}

		.langmenu-active
		{
			@include display-inline-flex(center, space-between);
			padding: 10px;

			.la
			{
				margin-left: 12px;
			}
		}

		.langmenu-container
		{
			@include opacity(0);
			@include pointer-events(none);
			position: absolute;
			top: 0;
			@include display-inline-flex(flex-start, space-between);
			background: $white-color;
			@include box-shadow(0px 6px 8px 2px rgba(0,0,0,0.06));
			@include border-radius(6px);
			padding: 10px 20px 10px 10px;
			@include transition;

			.lang-arrow
			{
				position: absolute;
				top: 14px;
				right: 12px;
				color: $brand-success-01;
			}

			.langmenu-list
			{
				@include list-unstyled;
				margin-right: 20px;
				li
				{
					height: 24px;
					margin-bottom: 10px;

					&:last-child
					{
						margin-bottom: 0;
					}
				}
			}
		}

		&:hover, &:active, &:focus
		{
			.langmenu-container
			{
				@include opacity(1);
				@include pointer-events(all);
			}
		}
	}
}

.navbar-container
{
	ul
	{
		li
		{
			padding: 0px 33px;
			.menu-link
			{
				@include font-p(rem-calc(16px), $weight-regular, rem-calc(26px), $brand-gray-01);
				@include transition;
			}

			&:hover, &:active, &:focus, &.active
			{
				.menu-link
				{
					color: $brand-success-01;
				}
			}
		}
	}

	@media (max-width: 992px) 
	{
	}
}