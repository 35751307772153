/*----------  Grid Spacing  ----------*/

%row, .row
{
	@include row-spacing($grid-space-default);

	@each $space in $grid-spaces
	{
		&.row-#{$space}
		{
			@include row-spacing($space);
		}

		&.row-md-#{$space}
		{
			@include media-breakpoint-down(md)
			{
				@include row-spacing($space);
			}
		}

		&.row-sm-#{$space}
		{
			@include media-breakpoint-down(sm)
			{
				@include row-spacing($space);
			}
		}

		&.row-xs-#{$space}
		{
			@include media-breakpoint-down(xs)
			{
				@include row-spacing($space);
			}
		}
	}
}