.manda
{
	.hero-wrapper
	{
		.service-page-hero
		{
			min-height: 395px;

			.hero-content-container
			{
				top: 210px;
			}
			
			@media (max-width: 575px) 
			{
				min-height: 200px;

				.hero-content-container
				{
					top: 120px;
				}
			}
		}
	}

	.manda-toggler-container
	{
		padding-top: 395px;
		text-align: center;
		.manda-block
		{
			cursor: default;
			padding: 120px 0;
			@include position(relative, 5);
			@include transition;
			@include box-shadow(0px 40px 40px rgba($black-color, 0.04));

			.manda-image
			{
				@include transition;
				padding-bottom: 5px;
				padding-top: 0;
			}
			.manda-content
			{
				@include transition;
				padding-top: 5px;
				padding-bottom: 0;
			}

			&:before {
				content: '';
				height: 3px;
				background-color: $brand-success-02;
				width: 0;
				position: absolute;
				top: 0;
				left: 50%;
				@include transform(translateX(-50%))
				@include transition;
			}

			&:hover, &:active, &:focus
			{
				.manda-image
				{
					padding-bottom: 0px;
					padding-top: 5px;
				}
				.manda-content
				{
					padding-bottom: 5px;
					padding-top: 0px;
				}
			}

			&.active
			{
				@include box-shadow(none);
				background: $brand-gray-05;
				
				.manda-image
				{
					padding-bottom: 5px;
					padding-top: 0px;
				}
				.manda-content
				{
					padding-bottom: 0px;
					padding-top: 5px;
				}

				&:before {
					width: 100%;
				}
			}
		}

		@media (max-width: 767px) 
		{
			.manda-block
			{
				padding: 60px 0;
			}
		}

		@media (max-width: 575px) 
		{
			padding-top: 200px;
			.manda-block
			{
				.manda-image
				{
					display: none;
				}

				.manda-content
				{
					h2
					{
						font-size: rem-calc(18px);
					}
				}
			}
		}
	}

	.acquisition-section, .sell-section
	{
		@include max-width(1280px);
		padding: 80px;

		.section-content
		{
			text-align: center;
			padding: 80px 0;
			@include max-width(920px);
		
			p
			{
				margin-top: 20px;
			}
		}

		.acquisition-content, .sell-content
		{
			padding: 80px 0;
			
			p
			{
				&.lg
				{
					@include font-p(rem-calc(24px), $weight-light, rem-calc(32px), $brand-gray-01);
				}
			}
		}

		@media (max-width: 991px) 
		{
			.section-content, .acquisition-content, .sell-content
			{
				padding: 20px 0;
			}
		}
		@media (max-width: 767px) 
		{
			.acquisition-content, .sell-content
			{
				text-align: center;
				p
				{
					&.lg
					{
						font-size: rem-calc(15px);
						line-height: rem-calc(20px);
						margin-top: 20px;
					}
				}
			}
		}

		@media (max-width: 575px) 
		{
			padding: 40px 15px;
		}
	}

	.acquisition-process-container, .sell-process-container
	{
		.acquisition-process-block, .sell-process-block
		{
			cursor: default;
			background: $white-color;
			padding: 50px;
			margin-bottom: 30px;
			@include box-shadow(inset 0px 3px 5px 0px rgba(9,203,146,0), 0px 22px 30px rgba($black-color, 0.05));
			@include transition;

			

			h4,h5,p
			{
				@include transition;
				@include font-p(rem-calc(17px), $weight-regular, rem-calc(24px), $brand-gray-02);
				margin-bottom: 0;
			}

			h4
			{
				font-weight: $weight-medium;
				line-height: rem-calc(26px);
				color: $brand-gray-01;
			}

			h5
			{
				font-weight: $weight-light;
				line-height: rem-calc(26px);
			}

			p
			{
				color: rgba($white-color, 0);
			}

			.process-block-inner
			{
				@include position(relative, 5);
				min-height: 205px;

				.acquisition-process-icon, .sell-process-icon, .process-block-title, .process-block-content
				{
					@include transition;
				}

				.acquisition-process-icon, .sell-process-icon
				{
					position: absolute;
					top: 0;	
					left: 0;	
				}

				.process-block-title
				{
					width: 100%;
					position: absolute;
					top: 100%;	
					left: 0;
					@include transform(translateY(-100%));
				}

				.process-block-content
				{
					position: absolute;
					top: 100%;	
					left: 0;
					@include transform(translateY(-100%));	
				}

				.block-count
				{
					@include font-p(rem-calc(255px), $weight-hairline, 0.8, rgba($white-color, 0.2));
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
				}
			}

			&:hover, &:active, &:focus
			{
				@include box-shadow(inset 0px 3px 5px 0px rgba(9,203,146,0.3), 0px 36px 46px rgba($brand-success-02, 0.24));
				background: $brand-success-03;

				h4,h5,p
				{
					color: $white-color;
				}

				.process-block-inner
				{
					.acquisition-process-icon
					{
						left: 100%;
						@include transform(translateX(-100%));
					}

					.process-block-title
					{
						top: 0;
						left: 0;
						@include transform(translateY(0));
					}
				}
				
				img
				{
					@include filter(brightness(0) invert(1));
				}
			}

			&.sell-process-block
			{	
				.process-block-inner
				{
					overflow: hidden;
					.process-block-content
					{
						display: none;
					}
				}
				
				h4
				{
					position: absolute;
					top: 100%;
					@include transform(translateY(-100%));
				}

				h5
				{
					@include transform(translateY(100%));
				}
				
				&:hover, &:active, &:focus
				{
					
					h4
					{
						top: 0;
					}

					h5
					{
						@include transform(translateY(0%));
					}

					.process-block-inner
					{
						.acquisition-process-icon
						{
							left: 0;
							@include transform(translateX(0%));
						}

						.process-block-title
						{
							top: 100%;
							left: 0;
							@include transform(translateY(-100%));
						}
					}
				}
			}
		}
	}
}