.valuation-page
{
	.main-inner
	{
		@include background-defaults;
		background-size: contain;
		background-position: 330px -300px;
	}

	@media (max-width: 1440px)
	{
		.main-inner
		{
			background-position: 330px 0px;
		}
	}

	@media (max-width: 991px)
	{
		.main-inner
		{
			background-position: 150px 0px;
		}
	}
}

.valuation-section
{
	@include background-defaults;

	&-inner {
		padding: 0 30px;
		@media (max-width: 767px)
		{
			padding: 0;
		}
	}

	.hero-wrapper
	{
		padding-top: 370px;
		padding-bottom: 120px;
		.pre-title
		{
			@include font-p(rem-calc(14px), $weight-regular, 1.2, $brand-gray-02);
			margin-bottom: 12PX;
			letter-spacing: 1.4px;
			text-transform: uppercase;
		}
	}

	.content-wrapper
	{
		.content-container {
			@include display-inline-flex(center, space-between);
			margin-bottom: 100px;
		}

		.content-block {
			max-width: 450px;
			padding-right: 30px;
			margin-right: 120px;
		}

		.content-video-block {
			display: block;
			@include flex(1);
			@include order(2);
			.valuation-video-container {
				@include border-radius(20px);
				overflow: hidden;
				@include position;
				.play-icon {
					@include absolute-center;
				}
				.video-image {
					@include position(absolute, 3, 0, 0, 0, 0);
				}
			}
		}

		h4,p,li
		{
			color: $brand-gray-01;
			font-weight: $weight-regular;
		}

		h4
		{
			@include font-p(rem-calc(24px), $weight-regular, 1.3, $brand-gray-01);
			margin-bottom: 60px;
			letter-spacing: -0.6px;
		}

		ul
		{
			@include extends('.list-unstyled');
			margin-bottom: 60px;
		}

		li
		{
			@include position;
			padding-left: 18px;
			margin-bottom: 10px;
			&:before
			{
				content: '';
				width: 7px;
				height: 7px;
				border: 2px solid $brand-success-02;
				@include border-radius(10px);
				@include position(absolute, 5, 50%, 0, auto, auto);
				@include transform(translateY(-50%));
			}
		}

		.list-title
		{
			text-transform: uppercase;
		}

		.valuation-foot
		{
			margin-top: 120px;
			margin-bottom: 180px;
			@include display-inline-flex(center, space-between);

			.action-container
			{
				text-align: center;
				margin: 40px 0 0px;
			}

			.small, .small a
			{
				@include font-p(rem-calc(14px), $weight-regular, 1.3, $brand-gray-02);
				letter-spacing: -0.35px;

				a
				{
					color: $brand-success-01;
				}
			}

			.promise-wrapper {
				min-width: 450px;
				max-width: 450px;
				margin-right: 120px;
				background-color: $brand-success-04;
				border: 2px solid #b8f2e1;
				@include border-radius(20px);
				padding: 24px 40px;

				.promise-head {
					@include display-inline-flex(center, space-between);
					margin-bottom: 24px;

					h4 {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	@media (max-width: 1199px)
	{
		.hero-wrapper
		{
			padding-top: 250px;
			padding-bottom: 80px;
		}

		.content-wrapper
		{
			.content-container {
				@include flex-direction(column);

			}

			.content-block {
				max-width: 100%;
				padding-right: 0;
				margin: 0 auto;

				h4 {
					margin-bottom: 20px;
				}
			}

			.content-video-block {
				width: 100%;
				max-width: 70%;
				margin: auto;
				margin-bottom: 80px;
				@include order(0);

			}
			.valuation-foot
			{
				margin-top: 80px;
				margin-bottom: 80px;
			}
		}
	}

	@media (max-width: 991px)
	{
		.content-wrapper
		{
			.valuation-foot
			{
				.promise-wrapper {
					max-width: 50%;
					min-width: auto;
					margin-right: 30px;
				}

				.action-wrapper {
					text-align: center;
				}
			}
		}
	}

	@media (max-width: 767px)
	{
		.hero-wrapper
		{
			padding-top: 120px;
			padding-bottom: 40px;
		}

		.content-wrapper
		{
			.content-container {
				margin-bottom: 40px;
			}

			.content-video-block {
				max-width: 100%;
			}
			.valuation-foot
			{
				margin-top: 40px;
				margin-bottom: 0px;

				@include flex-direction(column);

				.promise-wrapper {
					max-width: 100%;
					min-width: auto;
					margin: 0 auto 50px;

					.promise-head {
						margin-bottom: 0px;
					}
				}

				.action-wrapper {
					text-align: center;
				}

				.action-container
				{
					margin: 40px 0;
				}
			}
		}
	}

	@media (max-width: 575px)
	{
		.hero-wrapper
		{
			// text-align: center;

			.pre-title
			{
				font-size: rem-calc(12px);
			}

			h1
			{
				font-size: rem-calc(32px);
				margin-top: 20px;
			}
		}

		.btn-bordered
		{
			font-size: rem-calc(14px);
			padding: 10px 15px;
		}
	}

	// E-book Page css
	.hero-wrapper{
		h1{
			letter-spacing: -3px;
		}
		.subtitle{
			font-size: 24px;
			font-weight: $weight-regular;
			letter-spacing: -0.6px;
		}
	}

	.content-wrapper{
		.content-container{
			// display: block;

			.ebook-content-block{
				@include display-flex(row,flex-start,space-between);
				.content{
					max-width: 570px;

					h4{
						margin-bottom: 80px;
					}
					h3{
						margin: 80px 0px 80px;
						font-weight: $weight-regular;
						letter-spacing: -0.9px;
					}
					.ebook-content-details{
						@include display-flex(column,flex-start,space-between);
					}
				}
				.media{
					max-width: 530px;
				}
			}
		}
	}
	@include media-breakpoint-down(lg){
		.content-wrapper{
			.content-container{
				.ebook-content-block{
					.content{
						.ebook-content-details{
							p:first-of-type{
								margin-right: 20px;
							}
						}
					}
				}
			}
		}
	}

	@include media-breakpoint-down(md){
		.hero-wrapper{
			h1{
				font-size: 50px;
			}
			.subtitle{
				font-size: $font-lg;
			}
		}

		.content-wrapper{
			.content-container{
				.ebook-content-block{
					.content{
						h4{
							font-size: $font-lg;
						}
						h3{
							font-size: 24px;
						}
					}
				}
			}
		}
	}
	@include media-breakpoint-down(sm){
		.content-wrapper{
			.content-container{
				.ebook-content-block{
					@include display-flex(column,center);
					.content{
						max-width: none;
						width: 100%;
					}
				}
			}
		}
	}

	@include media-breakpoint-down(xs){
		.content-wrapper{
			.content-container{
				.ebook-content-block{
					.content{
						.ebook-content-details{
							@include display-flex(column);
							p{
								max-width: none;
							}
						}
					}
				}
			}
		}
	}
}

.ebook-form-section{
	padding: 160px 0px;
	background-color: $brand-gray-05;

	.ebook-form-container{
		max-width: 608px;
		margin: 0px auto;

		.section-title{
			text-align: center;
			margin-bottom: 80px;
		}

		.row{
			.btn-container{
				width: 100%;
				padding: 0px 15px;
				margin-top: 15px;

				button{
					width: 100%;
				}
			}
		}
	}
}
