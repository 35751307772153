/* Icon Fade */
@mixin icon-fade {
	@include hacks();

	.hvr-icon {
		@include prefixed(transform, translateZ(0));
		@include prefixed(transition-duration, .5s);
		@include prefixed(transition-property, color);
	}

	&:hover,
	&:focus,
	&:active {

		.hvr-icon {
			color: #0F9E5E;
		}
	}
}
