.careers
{	
	.row
	{
		margin: 0;
	}
		
	.site
	{
	   padding: 1px 0;
	}

	.careers-section
	{
		@include max-width(1240px);
		padding: 0 15px;
	}

	.career-block
	{
		padding: 85px 155px;
		h2
		{
			@include font-p(rem-calc(48px), $weight-light, rem-calc(52px), $brand-gray-01);
			margin-bottom: 55px;
		}

		h4
		{
			@include font-p(rem-calc(17px), $weight-medium, rem-calc(26px), $brand-gray-01);
			margin-bottom: 0;
			text-transform: uppercase;
		}

		p
		{
			@include font-p(rem-calc(17px), $weight-regular, rem-calc(26px), $brand-gray-01);

			&:last-child
			{
				margin-bottom: 50px;
			}
		}

		ul
		{
			li
			{
				@include font-p(rem-calc(15px), $weight-medium, rem-calc(20px), $brand-gray-01);
				margin-bottom: 20px;
				padding-left: 30px;
				@include position(relative, 5);

				&:before {
					content: '\f17b';
					font-family: 'LineAwesome';
					color: $brand-success-01;
					position: absolute;
					left: 0;
				}
			}

			&:last-child
			{
				margin-bottom: 50px;
			}
		}

		.career-footer {
			margin: 20px 0;
			text-align: center;
			p {
				@include font-p(rem-calc(24px), $weight-light, rem-calc(32px), $brand-gray-01);
			}

			a {
				line-height: 30px;
				&:after
				{
					width: 100%;
				}
			}
		}

		@media (max-width: 991px) 
		{
			padding: 40px 60px;

			h2
			{
				font-size: rem-calc(36px);
				margin-bottom: 35px;
			}

			h4
			{
				font-size: rem-calc(15px);
			}

			p
			{
				font-size: rem-calc(15px);

				&:last-child
				{
					margin-bottom: 30px;
				}
			}

			li
			{
				font-size: rem-calc(13px);
				margin-bottom: 10px;
			}

			.career-footer {
				margin: 10px 0;
				p 
				{
					font-size: rem-calc(18px);
				}
			}

		}

		@media (max-width: 575px) 
		{
			padding: 30px;

			h2
			{
				font-size: rem-calc(24px);
				margin-bottom: 10px;
				font-weight: $weight-regular;
			}

		}
	}
}